import { baseApi } from "./baseApi";
import { TAttendance, TAttendanceStatus } from "../types/attendance.type";
import queryString from "query-string";
import { PaginatedResponse, Pagination } from "../../utils/utils";

type Position = {
  longitude: number;
  latitude: number;
};

const attendanceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTodaysTime: builder.query<TAttendance, void>({
      query: () => ({
        url: "/attendance/today/me",
      }),
    }),
    getAttendanceStatus: builder.query<any, void>({
      query: () => ({
        url: "/attendance/status",
      }),
    }),
    getAttendanceWorkReport: builder.query<any, any>({
      query: (body) => {
        return {
          url: `/attendance/employee/work-report?${queryString.stringify(
            body
          )}`,
        };
      },
    }),
    getUserAttendance: builder.query<
      PaginatedResponse<TAttendance> & {statistics: TAttendanceStatus},
      Pagination<TAttendance> & { id: string }
    >({
      query: (body) => ({
        url: `/attendance/user/${body.id}?${queryString.stringify(body)}`,
        method: "GET",
      }),
    }),
    clockIn: builder.mutation<TAttendance, Position>({
      query: (body) => ({
        url: "/attendance/clockin",
        method: "POST",
        body,
      }),
    }),
    breakIn: builder.mutation<TAttendance, void>({
      query: () => ({
        url: "/attendance/breakin",
        method: "PATCH",
      }),
    }),
    breakOut: builder.mutation<TAttendance, Position>({
      query: (body) => ({
        url: "/attendance/breakout",
        method: "PATCH",
        body,
      }),
    }),
    clockOut: builder.mutation<TAttendance, Position>({
      query: (body) => ({
        url: "/attendance/clockout",
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetTodaysTimeQuery,
  useGetAttendanceStatusQuery,
  useGetAttendanceWorkReportQuery,
  useClockInMutation,
  useBreakInMutation,
  useBreakOutMutation,
  useClockOutMutation,
  useGetUserAttendanceQuery,
} = attendanceApi;
