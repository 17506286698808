import { useEffect, useState } from "react";

export const Timer = ({
  running,
  startTime,
}: {
  running: boolean;
  startTime: number;
}) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    setTime(startTime);
  }, [startTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [running]);

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <span className="text-3xl font-bold">
        <span>{("0" + Math.floor((time / 3600000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
      </span>
      <span className="text-secondary text-sm">
        {new Date().toDateString()}
      </span>
    </div>
  );
};
