import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import Button from "./Button";

import { ReactComponent as Loader } from "../../assets/icons/oval-loading.svg";
import i18n from "../../app/i18n";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  children: ReactNode;
  hasFooter: boolean;
  alternativeFooter?: JSX.Element;
  buttonType?: "button" | "submit" | "reset" | undefined;
  isOpen: boolean;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelClick?: () => void;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  confirmText?: string;
  cancelText?: string;
};

const backdrop = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const modal = {
  hidden: { opacity: 0, y: "-10vh" },
  visible: { opacity: 1, y: 0 },
};

const Modal: React.FC<Props> = ({
  title,
  children,
  hasFooter,
  isOpen,
  onClose,
  className,
  alternativeFooter,
  onConfirm,
  onCancelClick,
  buttonType,
  cancelText,
  isConfirmDisabled,
  isCancelDisabled,
  confirmText,
  isLoading,
}) => {
  if (isOpen) {
    document.body.style.overflowY = "hidden";
  } else {
    document.body.style.overflowY = "auto";
  }

  const { t } = useTranslation();

  return ReactDOM.createPortal(
    <>
      {/* Main Modal */}
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            className={`modal-backdrop dark:bg-gray-700 z-50`}
            onClick={onClose}
            variants={backdrop}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <motion.div
              className={`modal-content rounded-lg max-w-2xl z-50 relative w-full lang-${i18n.language} ${className}`}
              variants={modal}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={(e) => {
                //  no onClose if anything inside modal content is clicked
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div
                className="flex justify-between w-full items-start p-5 rounded-t  dark:border-gray-600"
                // dir={isRtl ? "rtl" : "ltr"}
              >
                <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-black">
                  {title}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5  inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                  onClick={onClose}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              {/* Modal Body */}
              {isLoading && (
                <div className="flex justify-center items-center absolute left-1/2 top-1/2 z-50">
                  <Loader className="w-8 h-8 stroke-current text-primary" />
                </div>
              )}

              <div
                className={`p-6 space-y-6 w-full modal-body overflow-y-auto ${
                  alternativeFooter ? "mb-5" : "mb-20"
                }  ${isLoading ? "opacity-30 pointer-events-none" : ""}`}
                // dir={isRtl ? "rtl" : "ltr"}
              >
                {children}
              </div>

              {/* Modal Footer */}
              {alternativeFooter && alternativeFooter}
              {hasFooter && (
                <div className="flex items-center justify-between p-6 space-x-2 rounded-b  border-gray-200 dark:border-gray-600 fixed bottom-0 bg-white w-full">
                  <Button
                    className={`w-56 bg-tertiary ${
                      isCancelDisabled !== undefined && isCancelDisabled
                        ? "opacity-50 pointer-events-none"
                        : ""
                    } `}
                    variant="secondary"
                    onClick={onCancelClick ?? onClose}
                  >
                    {cancelText ? cancelText : t("common.cancel")}
                  </Button>
                  <Button
                    className={`w-56 ${
                      isConfirmDisabled !== undefined && isConfirmDisabled
                        ? "opacity-50 pointer-events-none"
                        : ""
                    }`}
                    variant="primary"
                    type={buttonType ? buttonType : "submit"}
                    onClick={onConfirm}
                  >
                    {confirmText ? confirmText : t("common.confirm")}
                  </Button>
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>,
    document.getElementById("modal") as HTMLElement
  );
};

export default Modal;
