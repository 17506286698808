import React from "react";

type TProps = {
  subtitle: string;
  subtitleData: number;
  isLoading?: boolean;
};

export const StatusCard = (props: TProps) => {
  return (
    <div className="box_shadow_rb flex w-[330px] h-[130px] rounded-2xl flex-col items-start p-5 justify-center gap-2">
      {props.isLoading ? (
        <div className="w-14 h-5 bg-slate-200 animate-pulse"></div>
      ) : (
        <span className="text-xl">{props.subtitleData}</span>
      )}

      <span className="text-sm text-secondary">{props.subtitle}</span>
    </div>
  );
};
