import React, { useEffect, useState } from "react";
import { ReactComponent as PreviousIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Calander } from "../../assets/icons/calander.svg";
import i18n from "../../app/i18n";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

type Props = {
  onChange: (date: Date) => void;
};

export const CustomDateSlider = (props: Props) => {
  const [date, setDate] = useState(new Date());
  const handlePrev = () => {
    const year = date.getFullYear();
    const month = date.getMonth();
    if (month === 0) {
      setDate(new Date(year - 1, 11));
    } else {
      setDate(new Date(year, month - 1));
    }
  };
  const handleNext = () => {
    const year = date.getFullYear();
    const month = date.getMonth();
    if (month === 11) {
      setDate(new Date(year + 1, 0));
    } else {
      setDate(new Date(year, month + 1));
    }
  };
  // Call the onDateChange callback when the date changes
  useEffect(() => {}, [date]);
  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  useEffect(() => {
    props.onChange(date);
  }, [date]);
  return (
    <div className="flex justify-between border-b border-tertiary py-5 mx-5">
      <button onClick={handlePrev}>
        <PreviousIcon
          className={`fill-primary ${dir === "rtl" ? "rotate-180" : ""}`}
        />
      </button>
      <div className="flex gap-2 items-center">
        <Calander className="fill-primary" />
        <span className="text-primary">{`${
          months[date.getMonth()]
        } ${date.getFullYear()}`}</span>
      </div>
      <button onClick={handleNext}>
        <PreviousIcon
          className={`${dir === "rtl" ? "" : "rotate-180"} fill-primary`}
        />
      </button>
    </div>
  );
};
