import React, { useEffect, useState } from "react";
import "react-toggle/style.css";
import { ReactComponent as EditButton } from "../../../assets/icons/edit.svg";
import { useTranslation } from "react-i18next";
import { Attendance } from "../../settings/Attendance";
import { Leaves } from "../../settings/Leaves";
import { useGetUserQuery } from "../../../app/services/users";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditEmployee } from "./EditEmployee";

const tabs = [
  { label: "common.attendance", url: "attendance" },
  { label: "common.leaves", url: "leaves" },
  { label: "common.employeeInfo", url: "info" },
];

export const EmployeeProfile = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();

  const { isFetching: isFetchingUser, data: userData } = useGetUserQuery(
    params?.id ?? ""
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();

  const { hash } = useLocation();

  return (
    <div>
      <div className="flex gap-10 mt-5">
        <div className="w-3/12">
          <div className="flex flex-col">
            {/* <h1>
                <span className="text-2xl">Personal Settings</span>
                </h1> */}

            <div className="profile-container p-20 shadow-md flex flex-col place-items-center text-center rounded-2xl  w-11/12">
              <div className="profile-image relative">
                <img
                  src={userData?.avatar}
                  alt="profile"
                  className="rounded-full"
                  height="64px"
                  width="64px"
                />
                {/* <div className=" rounded-full absolute -right-2 -top-2 scale-105 p-2 bg-primary stroke-white !fill-white">
                      <EditButton
                        onClick={() => {
                          setSelectedTab(3);
                        }}
                      ></EditButton>
                    </div> */}
              </div>
              <div className="profile-name flex flex-col space-y-2 my-4">
                <h1 className="text-black text-base">{userData?.fullName}</h1>
                <p className="text-secondary">{userData?.roles}</p>
                <p className="text-secondary">{userData?.email}</p>
                <p className="text-secondary">{userData?.phoneNumber}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-4/6 flex-col ">
          {/* tabs section */}
          <div className="flex box_shadow_rb justify-around rounded-lg mb-5">
            {tabs.map((el, i) => {
              return (
                <span
                  onClick={() => {
                    setSelectedTab(i);
                    navigate("#" + el.url);
                  }}
                  className={`${
                    i !== selectedTab && "text-secondary"
                  } text-center w-full transition-all ${
                    hash === "#" + el.url && "bg-primary !text-white"
                  }  ${i === 0 && "rounded-l-lg"} ${
                    i === tabs.length - 1 && "rounded-r-lg"
                  }  p-4 cursor-pointer`}
                  key={i}
                >
                  {t(el.label)}
                </span>
              );
            })}
          </div>
          {hash === "#attendance" && <Attendance userId={params?.id} />}
          {hash === "#leaves" && <Leaves userId={params?.id} />}
          {hash === "#info" && userData && <EditEmployee data={userData} />}
          {/* {selectedTab === 3 && <PersonalSettings data={userData} />} */}
        </div>
      </div>
    </div>
  );
};
