import { useEffect, useState } from "react";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  FieldErrors,
  useFieldArray,
  Control,
} from "react-hook-form";
import { TFunction } from "react-i18next";
import { ScheduleFormPayload } from "./FormSection";
import {
  Day,
  TTimeTable,
  dayShortantEnumMap,
  daysOfWeek,
} from "../../../app/types/timeTable.type";

type FixedSubFormProps = {
  register: UseFormRegister<ScheduleFormPayload>;
  unregister: UseFormUnregister<ScheduleFormPayload>;
  setValue: UseFormSetValue<ScheduleFormPayload>;
  control: Control<ScheduleFormPayload>;
  t: TFunction<"translation", undefined>;
  errors: FieldErrors<ScheduleFormPayload>;
  dayShortantEnum: { label: string; value: string }[];
  days: string[];
  daysInput: string[];
  selectedSchedule?: TTimeTable;
};

const FixedSubForm = ({
  register,
  unregister,
  setValue,
  t,
  dayShortantEnum,
  days,
  daysInput,
  control,
  errors,
  selectedSchedule,
}: FixedSubFormProps) => {
  const [selectedDay, setSelectedDay] = useState(
    selectedSchedule?.isDummy
      ? [0, 1, 2, 3, 4]
      : selectedSchedule?.workDays.map((day) => dayShortantEnumMap[day?.day]) ??
          []
  );

  useEffect(() => {
    selectedSchedule &&
      setSelectedDay(
        selectedSchedule?.workDays.map((day) => dayShortantEnumMap[day?.day])
      );
  }, [selectedSchedule]);

  const handleSelectedDays = (value: string, index: number): void => {
    if (selectedDay.includes(index)) {
      console.log("here");
      console.log(index);
      setValue(`workDays.${index}`, {} as any);
      setSelectedDay(selectedDay.filter((el) => el !== index));
    } else {
      setSelectedDay((prev) => [...prev, index].sort());
      // setValue(`workDays.${index}.day`, dayShortantEnum[index].value);
    }
  };

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "workDays", // unique name for your Field Array
    }
  );

  console.log(fields);
  return (
    <>
      <div className="flex items-center justify-between my-10">
        <label
          className="font-normal mr-[3.5rem] rtl:mr-0 rtl:ml-[3.5rem]"
          htmlFor="name"
        >
          {t("schedule.workDays")}
        </label>

        {dayShortantEnum.map((type, i) => (
          <div
            onClick={() => {
              const day: Day = type.value as Day;
              fields.map((el) => el.day).includes(day)
                ? remove(fields.map((el) => el.day).indexOf(day))
                : append({ day });
              // handleSelectedDays(type.value, i);
              // setSelectedDay((prev) => [...prev, i]);
            }}
            key={i}
            className={`relative border transition-all border-tertiary cursor-pointer ${
              fields.map((el) => el.day).includes(type.value) &&
              "text-primary text-sm !border-primary bg-lightBlue"
            }  min-w-[5.5rem] text-center  rounded-xl py-2`}
          >
            {t(type.label)}
          </div>
        ))}
      </div>
      {fields
        .sort((a, b) => {
          const dayA = daysOfWeek.indexOf(a.day);
          const dayB = daysOfWeek.indexOf(b.day);
          return dayA - dayB;
        })
        .map((field, index) => {
          return (
            <div
              key={field.id}
              className="flex items-center justify-start gap-16"
            >
              <label className="min-w-[5rem] self-center pb-14" htmlFor="name">
                {t(days[dayShortantEnumMap[field.day as Day]])}
              </label>
              <div
                key={index}
                className="flex items-center justify-between gap-5  my-2"
              >
                <div>
                  <div className="relative border border-secondary border-opacity-20 rounded-xl p-3">
                    <input
                      {...register(`workDays.${index}.from`, {
                        required: t("common.notEmpty"),
                        pattern: {
                          value: /^([1-9]|1[0-2]):[0-5][0-9](am)$/i,
                          message: t("common.schedule.inputPattern"),
                          // "please enter correct format (12-hour clock format am/pm ex. 9:00am)"
                        },
                      })}
                      className="w-full lg:min-w-[10rem] outline-none border-none"
                      placeholder={t("common.timeExample")}
                    />
                  </div>
                  <div className="flex flex-col gap-1 flex-wrap max-w-[15rem] mt-5 min-h-[2.5rem] min-w-[15rem]">
                    {errors &&
                      errors.workDays &&
                      // @ts-ignore
                      errors.workDays[index]! && (
                        <span className="text-red-500 text-sm">
                          {/* @ts-ignore */}
                          {errors.workDays[index]?.from?.message}
                        </span>
                      )}
                  </div>
                </div>
                <span className="mx-2 pb-14">-</span>
                <div>
                  <div className="relative border border-secondary border-opacity-20 rounded-xl p-3">
                    <input
                      {...register(`workDays.${index}.to`, {
                        required: t("common.notEmpty"),
                        pattern: {
                          value: /^([1-9]|1[0-2]):[0-5][0-9](am|pm)$/i,
                          message: t("common.schedule.inputPattern"),
                        },
                      })}
                      className="w-full lg:min-w-[10rem] outline-none border-none"
                      placeholder={t("common.timeExampleEnd")}
                    />
                  </div>
                  <div className="flex flex-col gap-1 flex-wrap max-w-[15rem] mt-5 min-h-[2.5rem] min-w-[15rem]">
                    {errors &&
                      errors.workDays &&
                      // @ts-ignore
                      errors.workDays[index]! && (
                        <span className="text-red-500 text-sm">
                          {/* @ts-ignore */}
                          {errors.workDays[index]?.to?.message}
                        </span>
                      )}
                  </div>
                </div>
              </div>

              <input
                hidden
                {...register(`workDays.${index}.day`)}
                defaultValue={dayShortantEnum[index]?.value}
              />
            </div>
          );
        })}
    </>
  );
};

export default FixedSubForm;
