import queryString from "query-string";
import { PaginatedResponse, Pagination } from "../../utils/utils";
import { TUserRequest } from "../types/user-request.type";
import { baseApi } from "./baseApi";

const userRequestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeaveRequests: builder.query<
      PaginatedResponse<TUserRequest>,
      Pagination<TUserRequest>
    >({
      query: (payload) => ({
        url: `/user-request/my-employees?${queryString.stringify(payload)}`,
      }),
      providesTags: ["UserRequest"],
    }),
    getUserRequest: builder.query<
      PaginatedResponse<TUserRequest>,
      Pagination<TUserRequest> & { id: string }
    >({
      query: (payload) => ({
        url: `/user-request/user/${payload.id}?${queryString.stringify(
          payload
        )}`,
        method: "GET",
      }),
      providesTags: ["UserRequest"],
    }),
    getARequest: builder.query<TUserRequest, string>({
      query: (id) => ({
        url: `/user-request/${id}`,
      }),
      providesTags: ["UserRequest"],
    }),
    approveRequest: builder.mutation<TUserRequest, string>({
      query: (id) => ({
        url: `/user-request/approve/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["UserRequest"],
    }),

    declineRequest: builder.mutation<TUserRequest, string>({
      query: (id) => ({
        url: `/user-request/decline/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["UserRequest"],
    }),
  }),
});

export const {
  useGetLeaveRequestsQuery,
  useApproveRequestMutation,
  useDeclineRequestMutation,
  useLazyGetARequestQuery,
  useGetUserRequestQuery,
} = userRequestApi;
