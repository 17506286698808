import React, { FunctionComponent, SVGProps, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/rectangle.png";
import close from "../assets/images/close.png";
import { ReactComponent as HomeIcon } from "../assets/icons/home-icon.svg";
import { ReactComponent as Clock } from "../assets/icons/clock.svg";
import { ReactComponent as Calander } from "../assets/icons/calander.svg";
import { ReactComponent as Bag } from "../assets/icons/bag.svg";
import { ReactComponent as People } from "../assets/icons/people.svg";
import { ReactComponent as Location } from "../assets/icons/location.svg";
import { ReactComponent as Person } from "../assets/icons/person.svg";
import { motion } from "framer-motion";
import i18n from "../app/i18n";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

type TProps = {
  name: string;
  styles: string;
  path: string;
  setIsSelected: (name: string) => void;
  Svg: FunctionComponent<SVGProps<SVGSVGElement>>;
  isCollapsed: boolean;
  isSelected: boolean;
};

const analiticsNav: {
  name: string;
  path: string;
  svg: FunctionComponent<SVGProps<SVGSVGElement>>;
}[] = [
  { path: "dashboard", svg: HomeIcon, name: "common.overview" },
  {
    name: "common.timesheets",
    svg: Clock,
    path: "timesheets",
  },
];
const settingsNav: {
  name: string;
  path: string;
  svg: FunctionComponent<SVGProps<SVGSVGElement>>;
}[] = [
  {
    name: "common.people",
    path: "people",
    svg: People,
  },
  {
    name: "common.manageSchedule",
    svg: Calander,
    path: "schedules",
  },
  {
    name: "common.manageLocations",
    svg: Location,
    path: "locations",
  },
  {
    name: "common.manageRequests",
    svg: Bag,
    path: "requests",
  },
  {
    name: "common.personalSettings",
    svg: Person,
    path: "profile",
  },
];

const NavItem = ({
  name,
  path,
  styles,
  setIsSelected,
  Svg,
  isCollapsed,
  isSelected,
}: TProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className={`font-medium text-lg flex cursor-pointer items-center gap-1  w-full  py-3 px-5 rounded-md ${styles}`}
      onClick={() => {
        setIsSelected(path);
        navigate(`/${path === "dashboard" ? path : "dashboard/" + path}`);
      }}
    >
      <div className="flex justify-start gap-5 items-center">
        <Svg
          className={`${
            ["locations"].includes(path.toLowerCase())
              ? isSelected
                ? "stroke-black"
                : "stroke-secondary"
              : isSelected
              ? "fill-black"
              : "fill-secondary"
          }`}
        />
        <div>{!isCollapsed && t(name)}</div>
      </div>
    </div>
  );
};

const NavHeader = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  return <span className="ml-6 font-bold my-2 text-lg">{name}</span>;
};

type SidebarProps = {
  setIsHidden: () => void;
  isHidden: boolean;
  currentRoute: string;
};

const SideBar = ({ currentRoute, isHidden, setIsHidden }: SidebarProps) => {
  // const isSmallScreen = useMediaQuery({ query: "(max-width: 2000px)" });
  const [isSelected, setIsSelected] = useState(
    currentRoute === "dashboard" ? "dashboard" : currentRoute.split("/")[1]
  );
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1024px)" });

  const ulVariantsLtr = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        x: { stiffness: 1000, velocity: -100 },
      },
    },

    closed: {
      x: -600,
      opacity: 0,
      transition: {
        x: { stiffness: 1000, velocity: -100 },
      },
    },
  };

  const ulVariantsRtl = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        x: { stiffness: 1000, velocity: -100 },
      },
    },

    closed: {
      x: 600,
      opacity: 0,
      transition: {
        x: { stiffness: 1000, velocity: -100 },
      },
    },
  };
  const { t } = useTranslation();

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    document.body.dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
    localStorage.setItem("lng", lng);
    window.location.reload();
  };

  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();

  return (
    <>
      <motion.div
        animate={isHidden ? "closed" : "open"}
        variants={dir === "rtl" ? ulVariantsRtl : ulVariantsLtr}
        initial={false}
        className={`fixed ${
          dir === "rtl" ? "right-0" : "left-0"
        } w-96 bg-white transition-all border-r-2   border-opacity-10 z-[100] border-r-secondary
        h-full min-h-screen text-secondary `}
      >
        <div className="flex lg:justify-start justify-between items-center">
          <img
            src={logo}
            alt="logo"
            className="w-7/12 lg:w-9/12 p-4 ml-5 mt-5"
          />
          <img
            src={close}
            onClick={() => setIsHidden()}
            alt="close"
            className="w-14 h-14 p-4 ml-5 mt-5 cursor-pointer text-secondary"
          />
        </div>
        <div className="flex flex-col  items-start  justify-start w-full p-4 h-full">
          <NavHeader name={t("analytics")} />
          {analiticsNav.map((item) => {
            return (
              <NavItem
                key={item.name}
                path={item.path}
                isCollapsed={isCollapsed}
                isSelected={isSelected === item.path}
                name={item.name}
                setIsSelected={() => setIsSelected(item.path)}
                styles={
                  !isCollapsed && isSelected === item.path
                    ? "bg-gray text-textMain"
                    : ""
                }
                Svg={item.svg}
              />
            );
          })}
          <NavHeader name={t("common.settings")} />
          {settingsNav.map((item) => {
            return (
              <NavItem
                key={item.name}
                path={item.path}
                isSelected={isSelected === item.path}
                isCollapsed={isCollapsed}
                name={t(item.name)}
                setIsSelected={() => setIsSelected(item.path)}
                styles={
                  !isCollapsed && isSelected === item.path
                    ? "bg-gray text-textMain"
                    : ""
                }
                Svg={item.svg}
              />
            );
          })}
          <div className="flex justify-start items-center gap-4 p-4">
            <button
              onClick={() => {
                handleLanguageChange("en");
              }}
            >
              English
            </button>
            <button
              onClick={() => {
                handleLanguageChange("ku");
              }}
            >
              کوردی
            </button>
            <button
              onClick={() => {
                handleLanguageChange("ar");
              }}
            >
              عربی
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SideBar;
