import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { privateRoutes } from "../../app/private-routes";
import { ProtectedRoutes } from "../../app/ProtectRoutes";
import SideBar from "../../components/Sidebar";
import menu from "../../assets/images/menu.png";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setUser } from "../../app/slices/authSlices";
import "./../../App.css";
import i18n from "../../app/i18n";
import { useOnClickOutside } from "usehooks-ts";
import { TimesheetContext } from "../../utils/TimesheetContext";

export const Container = () => {
  const name = localStorage.getItem("fullName");
  const avatar = localStorage.getItem("avatar");

  const dispatch = useDispatch();

  const location = useLocation();
  const [dropDownHidden, setDropDownHidden] = useState(true);
  const [isHidden, setIsHidden] = useState(true);

  const [activeUserId, setActiveUserId] = useState<string>("");
  const navigate = useNavigate();

  const ref = useRef(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/request/user` ?? "", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        res.status !== 200 && navigate("/login");
        return res.json();
      })
      .then((data) => {
        dispatch(setUser(data));
      })
      .catch((e) => {
        navigate("/login");
      });
  }, [location.pathname, token, navigate]);

  const { t } = useTranslation();
  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();

  const handleClickOutside = () => {
    // Your custom logic here
    setDropDownHidden(true);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={`flex flex-row ${dir === "rtl" ? "dir-rtl" : "dir-ltr"}`}>
      <ToastContainer />
      <SideBar
        isHidden={isHidden}
        setIsHidden={() => setIsHidden(!isHidden)}
        currentRoute={location.pathname.substring(1)}
      />
      <div className="bg-lightGray flex-1  lg:py-2 flex flex-col">
        <div className="flex  justify-between   lg:px-5 lg:border-b-2 p-5 mb-5 lg:border-secondary lg:border-opacity-10  items-center">
          {/* <span className="text-textMain font-bold text-2xl hidden lg:block"> */}
          <img
            onClick={() => setIsHidden(!isHidden)}
            className={`w-8 h-8 m-5 cursor-pointer z-50`}
            src={menu}
            alt="menu"
          />
          {/* {capitalizeFirstLetter(
              location.pathname.split("/")[
                location.pathname.split("/").length - 1
              ]
            )} */}
          {/* </span> */}
          <div
            ref={ref}
            onClick={() => setDropDownHidden(!dropDownHidden)}
            className="flex gap-4 items-center relative cursor-pointer"
          >
            <span className="text-secondary hidden lg:block">{name}</span>
            <img
              className="h-10 w-10 rounded-3xl"
              src={
                avatar && avatar?.length > 0
                  ? avatar
                  : `https://ui-avatars.com/api/?name=${name}`
              }
              alt="random"
            />
          </div>
          <div
            className={`absolute top-20 ltr:right-5  rtl:left-5  p-3 min-w-[9rem] min-h-[9rem]  z-50 bg-white ${
              dropDownHidden ? "invisible opacity-0" : "visible opacity-100"
            } transition-all flex flex-col justify-evenly items-center  border border-tertiary rounded-lg`}
          >
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate("profile");
              }}
            >
              {t("common.personalSettings")}
            </span>

            <span
              className="cursor-pointer"
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              {t("logout")}
            </span>
          </div>
        </div>
        <div className="mx-12">
          <TimesheetContext.Provider value={{ activeUserId, setActiveUserId }}>
            <Routes>
              <Route element={<ProtectedRoutes />}>
                {privateRoutes.map(({ path, Element }, index) => {
                  return (
                    <Route key={index} path={path} element={<Element />} />
                  );
                })}
              </Route>
            </Routes>
          </TimesheetContext.Provider>
        </div>
      </div>
    </div>
  );
};
