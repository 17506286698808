import React from "react";

import { ReactComponent as CaretDownIcon } from "../../assets/icons/caret-down-icon.svg";
import { ReactComponent as LeftChevron } from "../../assets/icons/left-chevron.svg";
import i18n from "../../app/i18n";

type Props = {
  direction: "left" | "right" | "up" | "down";
  type: "caret" | "chevron";
} & React.SVGAttributes<SVGElement>;
export default function Caret({
  direction,
  type,
  ...rest
}: Props): JSX.Element {
  const isRtl = i18n.dir() === "rtl";
  // Create a transform map, change the rotation based on the isRtl prop
  const caretTransform = {
    left: isRtl ? "rotate(-90deg)" : "rotate(90deg)",
    right: isRtl ? "rotate(90deg)" : "rotate(-90deg)",
    up: "rotate(180deg)",
    down: "",
  };

  const chevronTransform = {
    left: isRtl ? "rotate(180deg)" : "rotate(0deg)",
    right: isRtl ? "rotate(0deg)" : "rotate(180deg)",
    up: "rotate(90deg)",
    down: "rotate(-90deg)",
  };

  if (type === "caret") {
    return (
      <CaretDownIcon
        className={`fill-current text-gray-500 text-opacity-80 ${rest.className}`}
        {...rest}
        style={{ transform: `${caretTransform[direction]}`, ...rest.style }}
      />
    );
  } else if (type === "chevron") {
    return (
      <LeftChevron
        className={`fill-current text-gray-500 text-opacity-80 ${rest.className}`}
        {...rest}
        style={{ transform: `${chevronTransform[direction]}`, ...rest.style }}
      />
    );
  } else {
    return <></>;
  }
}
