import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n from "../../app/i18n";
import "./../../App.css";
import { Spinner } from "../../components/UI/Spinner";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../../app/services/auth";
import { useNavigate, useParams } from "react-router-dom";
export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ password: string; confirmPassword: string }>();

  const [resetPassword, resetResult] = useResetPasswordMutation();
  const params = useParams<{ id: string; token: string }>();

  const onSubmit = (data: { password: string; confirmPassword: string }) => {
    params.id &&
      params.token &&
      resetPassword({
        password: data.password,
        id: params.id,
        token: params.token,
      });
  };

  useEffect(() => {
    if (resetResult.isSuccess) {
      toast.success(t("common.success") as string, {
        position: "top-center",
        autoClose: 1500,
      });
      resetResult.reset();
    } else if (resetResult.isError) {
      toast.error(t("common.errorOccured") as string, {
        position: "top-center",
        autoClose: 1500,
      });
      resetResult.reset();
    }
  }, [resetResult]);

  useEffect(() => {}, []);
  const { t } = useTranslation();

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);

    // document.body.dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();

    localStorage.setItem("lng", lng);
  };

  const navigate = useNavigate();
  console.log(errors);

  // fix for kurdish rtl issue
  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <div className={`h-screen ${dir === "rtl" ? "dir-rtl" : "dir-ltr"}`}>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md lg:max-w-lg w-full space-y-10">
          <h1 className="text-center font-bold text-2xl">
            {t("resetPassword.resetNow")}
          </h1>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div>
              <label htmlFor="password" className="font-light text-sm pb-1">
                {t("password")}
              </label>
              <input
                {...register("password", {
                  validate: (value) => {
                    // Password must contain at least one capital letter, one number, and one symbol.
                    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
                    return regex.test(value)
                      ? true
                      : t("resetPassword.passwordRegex");
                  },
                })}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className={`appearance-none mt-2 border border-secondary border-opacity-40 rounded-none relative block w-full px-3 py-2 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm ${
                  errors.password && "border-2 border-red-700 outline-none"
                }`}
              />
              <span className="text-red-500 text-sm">
                {errors.password && errors.password.message}
              </span>
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="font-light text-sm pb-1"
              >
                {t("resetPassword.confirmPassword")}
              </label>
              <input
                {...register("confirmPassword", {
                  validate: (val: string) => {
                    if (watch("password") !== val) {
                      return i18n.t(
                        "resetPassword.passwordsDontMatch"
                      ) as string;
                    }
                  },
                })}
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="current-password"
                required
                className={`appearance-none mt-2 border border-secondary border-opacity-40 rounded-none relative block w-full px-3 py-2 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm ${
                  errors.confirmPassword && "border-2 border-red-700"
                }`}
              />
              <span className="text-red-500 text-sm">
                {errors.confirmPassword && errors.confirmPassword.message}
              </span>
            </div>
            <div className="flex items-center justify-between ">
              <div className="text-sm">
                <span
                  onClick={() => navigate("/login")}
                  className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline cursor-pointer"
                >
                  {t("common.backToLogin")}
                </span>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group mt-5 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span> */}
                {resetResult.isLoading ? <Spinner /> : t("resetPassword.reset")}
              </button>
            </div>
          </form>
          <div className="flex justify-center items-center gap-4">
            <button
              onClick={() => {
                handleLanguageChange("en");
              }}
            >
              English
            </button>
            <button
              onClick={() => {
                handleLanguageChange("ku");
              }}
            >
              کوردی
            </button>
            <button
              onClick={() => {
                handleLanguageChange("ar");
              }}
            >
              عربی
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
