import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue } from "react-select";
import { toast } from "react-toastify";
import {
  useGetMyEmployeesQuery,
  useGetUserInvitationsQuery,
  useInviteUserMutation,
} from "../../../app/services/employees";
import Button from "../../../components/UI/Button";
import Modal from "../../../components/UI/Modal";
import { Spinner } from "../../../components/UI/Spinner";

type Props = {
  isAddModalOpen: boolean;
  setIsAddModalOpen: () => void;
};

interface Option {
  label: string;
  value: string;
}

export const AddPeople = ({ isAddModalOpen, setIsAddModalOpen }: Props) => {
  const [inviteEmployee, inviteResult] = useInviteUserMutation();

  const [selectedValues, setSelectedValues] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (selectedOptions: MultiValue<Option>) => {
    setSelectedValues(selectedOptions as Option[]);
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      if (isValidEmail(inputValue)) {
        console.log(inputValue);
        if (
          employees?.result.map((el) => el.user.email).includes(inputValue) ||
          data?.map((el) => el.email).includes(inputValue)
        ) {
          console.log("here");
          return toast.error(t("people.emailExists") as string, {
            position: "top-center",
            autoClose: 1500,
          });
        }
        const newOption = { value: inputValue, label: inputValue };
        setSelectedValues([...selectedValues, newOption]);
        setInputValue("");
      } else {
        toast.error(t("common.enterValidEmail") as string, {
          position: "top-center",
          autoClose: 1500,
        });
      }
    }
  };

  const { data } = useGetUserInvitationsQuery(undefined);
  const { data: employees } = useGetMyEmployeesQuery({
    page: 1,
    limit: 1000000,
  });

  useEffect(() => {
    if (inviteResult.isSuccess) {
      toast.success(t("common.success") as string, {
        position: "top-center",
        autoClose: 1500,
      });
      setSelectedValues([]);
    } else if (inviteResult.isError)
      toast.error(t("common.errorOccured") as string, {
        position: "top-center",
        autoClose: 1500,
      });
  }, [inviteResult]);

  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen()}
        hasFooter={false}
        alternativeFooter={
          <div className="text-secondary pb-5 ml-8 underline">
            <span
              onClick={() => {
                navigator.clipboard.writeText("hey");
                toast.success(t("common.copied") as string, {
                  position: "top-center",
                  autoClose: 1500,
                });
              }}
              className="cursor-pointer"
            >
              {t("people.addPeople.copyLink")}{" "}
            </span>
          </div>
        }
        className="max-w-[31rem]"
        title={t("people.modal.addPeople")}
        // onConfirm={handleSubmit(onSubmit)}
        onConfirm={() => {
          navigator.clipboard.writeText("hey");
          toast.success(t("common.copied") as string, {
            position: "top-center",
            autoClose: 1500,
          });
        }}
        isLoading={false}
      >
        <Select
          isMulti
          options={[]}
          value={selectedValues}
          onChange={handleChange}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          isSearchable
          placeholder={t("people.addPeople.placeholder")}
          menuIsOpen={false}
          onKeyDown={handleKeyDown as any}
          classNamePrefix="custom-select"
        />

        <Button
          variant="primary"
          onClick={async () => {
            console.log(selectedValues);
            if (selectedValues.length < 1)
              return toast.error(t("people.addPeople.addEmail") as string, {
                position: "top-center",
                autoClose: 1500,
              });
            await inviteEmployee(selectedValues.map((el) => el.value));
          }}
          className="w-full py-2 rounded-md"
        >
          {inviteResult.isLoading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            t("common.submit")
          )}
        </Button>

        <div className="flex flex-col gap-5 max-h-64 overflow-y-auto">
          <span>{t("common.employees")}</span>
          {employees?.result?.map((el, i: number) => {
            return (
              <div key={i} className="flex gap-5 items-center cursor-pointer">
                <div
                  className={`w-8 flex justify-center items-center text-white h-8 rounded-[50%] border border-secondary relative bg-primary border-none transition-all`}
                >
                  <span>{el.user.email?.charAt(0)?.toUpperCase()}</span>
                </div>
                <span>{el.user.email}</span>
              </div>
            );
          })}
          {data?.map((el, i: number) => {
            return (
              <div key={i} className="flex gap-5 items-center cursor-pointer">
                <div
                  className={`w-8 flex justify-center items-center text-white h-8 rounded-[50%] border border-secondary border-dashed relative ${
                    el.status === "approved" && "bg-primary border-none"
                  } transition-all`}
                >
                  <span>
                    {el.status === "approved" &&
                      el.email.charAt(0)?.toUpperCase()}
                  </span>
                </div>
                <span>{el.email}</span>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
