import React, { useState } from "react";

import Select from "react-select";

type Tprops = {
  options?: { value: string; label: string }[];
  className?: string;
  handleChange?: (value: any) => void;
  defaultValue?: string;
  showBorder?: boolean;
};

export const SelectComponent = (props: Tprops) => {
  return props.options ? (
    <Select
      className={`box_shadow_b font-medium border-none border-0 rounded-lg p-1 cursor-pointer ${props.className}`}
      options={props.options}
      isSearchable={false}
      placeholder=""
      defaultValue={props.options?.find(
        (el) => el.value === props.defaultValue
      )}
      styles={{
        //@ts-ignore
        control: (styles) => ({
          ...styles,
          border: props.showBorder ? "1" : 0,
          boxShadow: "none",
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={props.handleChange}
    />
  ) : (
    <></>
  );
};
