import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { LocationCard } from "./LocationCard";
import Modal from "../../../components/UI/Modal";
import { toast } from "react-toastify";
import Map from "./Map";
import {
  useAddLocationMutation,
  useDeleteLocationMutation,
  useEditLocationMutation,
  useGetLocationsQuery,
} from "../../../app/services/location";
import { TLocation } from "../../../app/types/location.type";
import i18n from "../../../app/i18n";

export const Locations = () => {
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<TLocation | undefined>();
  const [locations, setLocations] = useState<TLocation[] | undefined>();

  const [search, setSearch] = useState("");
  const [locationFormData, setLocationFormData] = useState<{
    title: string;
    description: string;
  }>();

  const [coordinatesData, setCoordinatesData] = useState<{
    radius: number;
    longitude: number;
    latitude: number;
  }>();

  const { data: locationData, isFetching } = useGetLocationsQuery(
    {
      page: 1,
      limit: 10,
      search: "title",
      searchVal: search,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [deleteLocation, deleteResult] = useDeleteLocationMutation();

  const [editLocation, editResult] = useEditLocationMutation();

  const [addLocation, locationResult] = useAddLocationMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (locationData?.result) {
      setLocations(locationData?.result);
      if (!selected) {
        setSelected(locationData?.result[locationData?.result.length - 1]);
      }
    }
  }, [locationData]);

  useEffect(() => {
    if (locationResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      locationResult.reset();
      setSelected(locationResult.data);
      setLocationFormData(undefined);
      setCoordinatesData(undefined);
    } else if (locationResult.isError) {
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
    } else if (deleteResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      deleteResult.reset();
    } else if (deleteResult.isError) {
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
    } else if (editResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      editResult.reset();
    } else if (editResult.isError) {
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
    }
    setLocationFormData(undefined);
  }, [locationResult, deleteResult, editResult]);

  const handleDelete = () => {
    setIsDeleteOpen(false);
    if (selected?._id) {
      if (selected.isDummy) {
        setLocations(locations?.filter((el) => el._id !== selected._id));

        toast(t("common.success") as string, {
          type: "success",
          autoClose: 2000,
          position: "top-center",
        });
      } else deleteLocation(selected?._id);
      setSelected(undefined);
    }
  };

  useEffect(() => {
    if (selected) {
      setLocationFormData({
        description: selected?.description,
        title: selected?.title,
      });

      setCoordinatesData({
        ...coordinatesData,
        latitude: selected.coordinates[0],
        longitude: selected.coordinates[1],
        radius: selected?.radius,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (locations && locations?.length < 1) setSelected(undefined);
  }, [locations]);

  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <section className="flex w-full justify-start gap-10 -mt-5 max-h-screen">
      <div className="border-r-2 min-h-screen pr-3 pt-2 border-tertiary flex flex-col gap-10">
        <div className="flex gap-2 -ml-3">
          <div className="relative border border-secondary border-opacity-20 rounded-xl p-2">
            <input
              onChange={(e) => {
                setSelected(undefined);
                setSearch(e.target.value);
              }}
              className="w-full lg:min-w-[296px] outline-none border-none"
              placeholder={t("locations.searchForLocations")}
            />
            <Search
              className={`absolute ${
                dir === "rtl" ? "left-4" : "right-4"
              } bottom-5`}
            />
          </div>
          <button
            onClick={() => {
              if (
                locations &&
                locations?.filter((el) => el.isDummy)?.length > 0
              ) {
                return alert(t("common.unsavedChanges"));
              }
              const dummyLocation = {
                title: "Untitled",
                description: "lorem epsum slaw chone",
                coordinates: [45.413171, 35.559834],
                radius: 10,
                _id: Math.random().toString(),
                isDummy: true,
              };
              // setIsAddModalOpen(true);
              locations &&
                setLocations((locations) =>
                  locations ? [...locations, dummyLocation] : []
                );
              setSelected(dummyLocation);
            }}
            className="bg-primary rounded-lg px-6"
          >
            <Plus />
          </button>
        </div>

        <div
          className={`flex flex-col gap-[0.1rem] h-[80vh] overflow-y-auto ${
            isFetching && "blur"
          }`}
        >
          {locations?.map((location, i) => {
            return (
              <LocationCard
                onDelete={() => {
                  setIsDeleteOpen(true);
                }}
                radius={location.radius}
                getTitleAndDescription={(data: {
                  title: string;
                  description: string;
                }) => {
                  setLocationFormData({
                    ...locationFormData,
                    title: data.title,
                    description: data.description,
                  });
                }}
                getRadius={(radius: number) => {
                  selected &&
                    setSelected({
                      ...selected,
                      radius,
                      title: locationFormData?.title ?? selected.title,
                      description:
                        locationFormData?.description ?? selected.description,
                    });
                }}
                key={location._id}
                name={location.title}
                description={location.description}
                isSearching={search.length > 0}
                selected={location._id === selected?._id}
                setSelected={() => {
                  if (locations.filter((el) => el.isDummy).length > 0) {
                    if (location._id !== selected?._id)
                      return alert(t("common.unsavedChanges"));
                  }
                  location._id !== selected?._id && setSelected(location);
                }}
              />
            );
          })}
        </div>
      </div>
      {selected && (
        <Map
          className="w-full"
          getCoordinates={(data: {
            longitude: number;
            latitude: number;
            radius: number;
          }) =>
            setSelected({
              ...selected,
              coordinates: [data.longitude, data.latitude],
            })
          }
          isDummy={selected?.isDummy}
          radius={selected?.radius}
          onSave={() => {
            if (locationFormData && coordinatesData) {
              if (selected.isDummy) {
                addLocation({
                  ...locationFormData,
                  coordinates: selected.coordinates,
                  radius: selected.radius,
                });
              } else {
                editLocation({
                  ...locationFormData,
                  coordinates: selected.coordinates,
                  radius: selected.radius,
                  _id: selected._id ?? "",
                });
              }
            }
          }}
          lat={selected.coordinates[1]}
          lng={selected.coordinates[0]}
        />
      )}

      <Modal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        hasFooter={true}
        title={t("schedule.modal.titleDelete")}
        onConfirm={handleDelete}
        isLoading={false}
      >
        <h3 className="text-xl py-4 pb-5 text-description">
          {t("schedule.modal.deleteTitle")}
        </h3>
      </Modal>
    </section>
  );
};
