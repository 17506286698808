import { useTranslation } from "react-i18next";
import { RequestStatus } from "../../../app/types/user-request.type";

type LeaveTypeCellProps = {
  value: string;
};

export const StatusTypeCell = ({ value }: LeaveTypeCellProps) => {
  const getStatusTypeColor = (leaveType: string) => {
    switch (leaveType) {
      case RequestStatus.Declined:
        return "bg-red-50 text-red-400  text-red-400";

      case RequestStatus.Pending:
        return "bg-yellow-50 text-yellow-400  text-yellow-400";

      case RequestStatus.Approved:
        return "bg-green-50 text-green-400  text-green-400";

      default:
        return "bg-gray-50 text-gray-400  text-gray-400";
    }
  };

  return (
    <div className="">
      <span
        className={
          " border-2 px-5 py-2 rounded-lg text-sm " + getStatusTypeColor(value)
        }
      >
        {value[0].toUpperCase() + value.slice(1)}
      </span>
    </div>
  );
};
