import { Home } from "../routes/dashboard/home/Home";
import { Locations } from "../routes/dashboard/locations/Locations";
import { People } from "../routes/dashboard/people/People";
import { Schedules } from "../routes/dashboard/schedules/Schedules";
import { Timesheets } from "../routes/dashboard/timesheets/Timesheets";
import { Users } from "../routes/dashboard/users/Users";
import { Requests } from "../routes/dashboard/requests/Requests";
import { Settings } from "../routes/settings/Settings";
import { Leaves } from "../routes/settings/Leaves";
import { Attendance } from "../routes/settings/Attendance";
import { EmployeeProfile } from "../routes/dashboard/people/EmployeeProfile";

export const privateRoutes = [
  {
    path: "",
    Element: Home,
  },
  {
    path: "users",
    Element: Users,
  },
  {
    path: "settings",
    Element: Settings,
  },

  {
    path: "profile",
    Element: Settings,
  },
  {
    path: "timesheets",
    Element: Timesheets,
  },
  {
    path: "schedules",
    Element: Schedules,
  },

  {
    path: "requests",
    Element: Requests,
  },
  {
    path: "locations",
    Element: Locations,
  },
  {
    path: "people",
    Element: People,
  },

  {
    path: "people/:id",
    Element: EmployeeProfile,
  },

  {
    path: "leaves",
    Element: Leaves,
  },

  {
    path: "attendance",
    Element: Attendance,
  },
];
