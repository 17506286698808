import "react-toggle/style.css";
import { useTranslation } from "react-i18next";
import {
  useGetMeQuery,
  useUploadImageMutation,
} from "../../app/services/users";
import React, { useEffect, useState } from "react";
import { emailRegex } from "../../utils/validation";
import { EditUserPayload } from "../../app/types/user.type";
import { useEditMeMutation } from "../../app/services/users";

import { SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "../../components/UI/Spinner";
import { toast } from "react-toastify";
import Button from "../../components/UI/Button";
import { useSendVerificationMutation } from "../../app/services/auth";
import { getValueFromLang } from "../../utils/utils";

import { ReactComponent as EditButton } from "../../assets/icons/edit.svg";

export const Settings = () => {
  const { t } = useTranslation();

  const { data: userData } = useGetMeQuery();

  const [emailResetVisible, setEmailResetVisible] = useState(false);

  const [editMe, editUserData] = useEditMeMutation();
  const [uploadImage, uploadResult] = useUploadImageMutation();
  const [sendVerification, sendVerificationResult] =
    useSendVerificationMutation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    fullName: string;
    newEmail: string;
    verificationCode: string;
  }>({
    defaultValues: {},
  });

  const onSubmit: SubmitHandler<EditUserPayload> = (data: EditUserPayload) => {
    console.log(data);
    userData &&
      editMe({
        fullName: data.fullName,
        newEmail: data.newEmail,
        verificationCode: data.verificationCode,
      });
  };

  useEffect(() => {
    if (editUserData.isSuccess)
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
    else if (editUserData.isError) {
      const msgObj = editUserData?.error as unknown as {
        data: { errorMessage: { en: string; ku: string; ar: string } };
      };

      toast(
        getValueFromLang(msgObj.data?.errorMessage) ??
          (t("common.errorOccured") as string),
        {
          type: "error",
          autoClose: 2000,
          position: "top-center",
        }
      );
    }
  }, [editUserData]);

  useEffect(() => {
    reset(userData);
  }, [userData]);

  useEffect(() => {
    if (sendVerificationResult.isSuccess)
      toast(t("common.codeSent") as string, {
        type: "success",
        autoClose: 5000,
        position: "top-center",
      });
    else if (sendVerificationResult.isError) {
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
    }
  }, [sendVerificationResult]);

  useEffect(() => {
    if (uploadResult.isSuccess) {
      editMe({
        avatar: uploadResult.data[0].Location,
      });
    }
  }, [uploadResult]);

  return (
    <div>
      <div className="flex gap-10 mt-5">
        <div className="w-3/12">
          <div className="flex flex-col">
            {/* <h1>
                <span className="text-2xl">Personal Settings</span>
                </h1> */}

            <div className="profile-container p-20 shadow-md flex flex-col place-items-center text-center rounded-2xl  w-11/12">
              <div className="profile-image relative">
                <img
                  src={userData?.avatar}
                  alt="profile"
                  className="rounded-full"
                  height="64px"
                  width="64px"
                />
                <div className=" rounded-full absolute -right-2 -top-2 scale-105 p-2 bg-primary ">
                  <label>
                    <EditButton className="!fill-white cursor-pointer"></EditButton>
                    <input
                      onChange={async (e) => {
                        if (e.target?.files) {
                          uploadImage(e.target.files[0]);
                        }
                      }}
                      id="file-input"
                      className="hidden"
                      type="file"
                    />
                  </label>
                </div>
              </div>
              <div className="profile-name flex flex-col space-y-2 my-4">
                <h1 className="text-black text-base">{userData?.fullName}</h1>
                <p className="text-secondary">{userData?.roles}</p>
                <p className="text-secondary">{userData?.email}</p>
                <p className="text-secondary">{userData?.phoneNumber}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/6 flex-col ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="basic-details flex flex-col space-y-10 ">
              <div className="flex flex-col space-y-6">
                <h1>
                  <span className="text-2xl text-textMain font-semibold ">
                    {t("common:settings.basicDetails")}
                  </span>
                </h1>
                <div className="flex flex-row">
                  <div className="flex flex-row items-center space-x-5 w-full">
                    <label className="text-base w-1/12">
                      {t("columns:name")}
                    </label>
                    <input
                      className="border-1 border-neutral-200 px-4 py-3 rounded-md w-full"
                      type="text"
                      {...register("fullName")}
                      defaultValue={userData?.fullName}
                    />
                  </div>
                </div>
                <div className="flex  flex-row">
                  <div className="flex flex-row items-center space-x-5 w-full">
                    <label className="text-base w-1/12">
                      {t("columns:role")}
                    </label>
                    <span className="px-4 py-3 rounded-md w-full">
                      {userData?.roles}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col space-y-6">
                <h1>
                  <span className="text-2xl text-textMain font-semibold">
                    {t("common:settings.loginAndSecurity")}
                  </span>
                </h1>
                <div className="flex flex-row">
                  <div className="flex flex-row items-center space-x-5 w-full">
                    <label className="text-base w-1/12">
                      {t("columns:email")}
                    </label>

                    <div className="w-full flex flex-col">
                      <input
                        className="border-1 flex-1 border-neutral-200 px-4 py-3 rounded-md w-full"
                        type="text"
                        // {...register("email", {
                        //   pattern: emailRegex,
                        //   minLength: 5,
                        // })}
                        disabled
                        defaultValue={userData?.email}
                      />
                      {/* {errors.email && (
                        <p className="text-error h-0 text-sm">
                          {t("validations:email.invalid")}
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between ">
                  <div
                    onClick={() => setEmailResetVisible(!emailResetVisible)}
                    className="text-sm"
                  >
                    <span className="font-medium cursor-pointer text-indigo-600 hover:underline">
                      {t("common.changeEmail")}
                    </span>
                  </div>
                </div>
                {emailResetVisible && (
                  <>
                    <div className="flex flex-row items-center w-full">
                      <label className="text-base w-3/12">
                        {t("columns:verification")}
                      </label>
                      <div className="w-full flex flex-col">
                        <input
                          className="border-1 flex-1 border-neutral-200 px-4 py-3 rounded-md w-full"
                          type="text"
                          {...register("verificationCode")}
                        />
                        {errors.verificationCode && (
                          <p className="text-error h-0 text-sm">
                            {t("validations:verificationCode.invalid")}
                          </p>
                        )}
                      </div>
                      <Button
                        onClick={() =>
                          userData &&
                          sendVerification({ email: userData?.email })
                        }
                        type="button"
                        variant="primary"
                        className="w-3/12 ml-2"
                      >
                        {t("common.sendVerfificationCode")}
                      </Button>
                    </div>
                    <span className="ml-36 text-sm cursor-pointer text-secondary hover:underline">
                      {t("common.dontHaveContact")}
                    </span>

                    <div className="flex flex-row items-center space-x-5 w-full">
                      <label className="text-base w-2/12">
                        {t("columns:newEmail")}
                      </label>

                      <div className="w-full flex flex-col">
                        <input
                          className="border-1 flex-1 border-neutral-200 px-4 py-3 rounded-md w-full"
                          type="text"
                          {...register("newEmail", {
                            pattern: emailRegex,
                            minLength: 5,
                          })}
                        />
                        {errors.newEmail && (
                          <p className="text-error h-0 text-sm">
                            {t("validations:newEmail.invalid")}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="flex flex-row">
                  <div className="flex flex-row items-center space-x-5 w-full">
                    <label className="text-base w-1/12">
                      {t("columns:phone")}
                    </label>
                    <input
                      className="border-1 border-neutral-200 px-4 py-3 rounded-md w-full"
                      disabled
                      defaultValue={userData?.phoneNumber}
                    />
                  </div>
                </div>
                {/* <div className="flex flex-row">
            <div className="flex flex-row items-center space-x-5 w-full">
              <label className="text-base w-1/12">
                {t("columns:password")}
              </label>

              <div className="w-full flex flex-col">
                <input
                  className="border-1 flex-1 border-neutral-200 px-4 py-3 rounded-md w-full"
                  type="password"
                  {...register("password", { pattern: passwordRegex })}
                />
                {errors.password && (
                  <p className="text-error h-0 my-0 text-sm">
                    {t("validations:password.invalid")}
                  </p>
                )}
              </div>
            </div>
          </div> */}
              </div>
              {/* <div className="flex flex-col space-y-6">
                <h1>
                  <span className="text-2xl text-textMain font-semibold">
                    {t("common:settings.notifications")}
                  </span>
                </h1>

                <div className="flex  flex-row">
                  <div className="flex flex-row items-center space-x-6 w-full ">
                    <div className="flex-col w-full py-4">
                      <span className="text-base">
                        {t("columns:monthlyReports")}
                      </span>
                      <br></br>
                      <span className="text-sm text-secondary">
                        Get monthly reports by email
                      </span>
                    </div>
                    <Toggle
                      id="cheese-status"
                      icons={false}
                      defaultChecked={monthlyChecked}
                    />
                  </div>
                </div>
                <div className="flex  flex-row">
                  <div className="flex flex-row items-center space-x-5 w-full">
                    <div className="flex-col w-full">
                      <span className="text-base">
                        {t("columns:receivePushNotifications")}
                      </span>
                      <br></br>
                      <span className="text-sm text-secondary">
                        Recieve clock in reminders and alerts
                      </span>
                    </div>
                    <Toggle
                      id="cheese-status"
                      defaultChecked={pushNotifications}
                      icons={false}
                      onChange={() => {
                        setPushNotifications(!pushNotifications);
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="flex flex-row justify-center">
                <button
                  className="bg-primary text-white font-normal py-2 px-4 rounded w-full"
                  type="submit"
                >
                  {editUserData.isLoading ? <Spinner /> : t("buttons:save")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
