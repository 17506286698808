import { PaginatedResponse, Pagination } from "../../utils/utils";
import { TEmployee } from "../types/employee.type";
import { EditUserPayload, TUser } from "../types/user.type";
import { baseApi } from "./baseApi";
import queryString from "query-string";

const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      PaginatedResponse<TEmployee>,
      Pagination<TEmployee>
    >({
      query: (body) => {
        return {
          url: `/user?${queryString.stringify(body)}`,
        };
      },
      providesTags: ["User"],
    }),
    getMe: builder.query<TUser, void>({
      query: () => {
        return {
          url: `/request/user`,
        };
      },
      providesTags: ["User"],
    }),

    getUser: builder.query<TUser, string>({
      query: (id) => {
        return {
          url: `/user/${id}`,
        };
      },
      providesTags: ["User"],
    }),
    editUser: builder.mutation<TUser, EditUserPayload & { id: string }>({
      query: (body) => ({
        url: `user/${body.id}`,
        method: "PATCH",
        body: {
          fullName: body.fullName,
          email: body.email,
          avatar: body.avatar,
          phoneNumber: body.phoneNumber,
          location: body.location,
        },
      }),
      invalidatesTags: ["User"],
    }),
    editMe: builder.mutation<
      TUser & { verificationCode: string },
      EditUserPayload
    >({
      query: (body) => ({
        url: `user/me`,
        method: "PATCH",
        body: {
          fullName: body.fullName,
          email: body.email,
          newEmail: body.newEmail,
          verificationCode: body.verificationCode,
          avatar: body.avatar,
          phoneNumber: body.phoneNumber,
          location: body.location,
        },
      }),
      invalidatesTags: ["User"],
    }),
    addTimetableToUser: builder.mutation<
      TUser,
      { id: string; timeTable: string }
    >({
      query: (body) => ({
        url: `user/add-timetable/${body.id}`,
        method: "PATCH",
        body: {
          timeTable: body.timeTable,
        },
      }),
      invalidatesTags: ["User"],
    }),

    uploadImage: builder.mutation<[{ Location: string }], any>({
      query: (imageFile) => {
        console.log(imageFile);
        const bodyFormData = new FormData();
        bodyFormData.append("files", imageFile);
        return {
          url: "upload",
          method: "POST",
          body: bodyFormData,
          formData: true, //add this line 👈
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetMeQuery,
  useEditUserMutation,
  useGetUserQuery,
  useAddTimetableToUserMutation,
  useEditMeMutation,
  useUploadImageMutation,
} = usersApi;
