import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDateSlider } from "../../components/UI/CustomDateSlider";
import { BasicTable } from "../../components/UI/Table";
import { getAttendanceColumns } from "../../data/columns";
import { ReactComponent as ClockIn } from "../../assets/icons/clock-in-icon.svg";
import { ReactComponent as ClockOut } from "../../assets/icons/clock-out-icon.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as Person } from "../../assets/icons/person.svg";
import { useGetUserAttendanceQuery } from "../../app/services/attendance";
import moment from "moment";

const StatsCard = ({
  title,
  Icon,
  time,
}: {
  title: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  time: string;
}) => {
  return (
    <div className="flex gap-3 items-center">
      <Icon className="fill-primary w-8 h-w-8" />
      <div className="flex flex-col">
        <span className="text-2xl font-semibold ">{time}</span>
        <span className="text-secondary">{title}</span>
      </div>
    </div>
  );
};

type Props = {
  userId?: string;
};

export const Attendance = (props: Props) => {
  const { t } = useTranslation();
  const attendanceColumns = getAttendanceColumns(t);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleFilterChange = (filter: any) => {
    console.log("filter", filter);
  };

  const { isFetching: isFetchingAttendance, data: attendanceData } =
    useGetUserAttendanceQuery(
      {
        id: props.userId ?? "",
        start: moment.utc(selectedDate).startOf("month").toISOString(),
        end: moment.utc(selectedDate).endOf("month").toISOString(),
      },
      { refetchOnMountOrArgChange: true }
    );

  return (
    <div className="box_shadow_rb">
      <CustomDateSlider onChange={(date: Date) => setSelectedDate(date)} />
      <div className="body-section">
        <div className="flex gap-28 m-8 ">
          <StatsCard
            title={t("leaves.avgClockIn")}
            time={
              attendanceData?.statistics.avgClockIn
                ? moment(attendanceData?.statistics.avgClockIn).format("LT")
                : "--"
            }
            Icon={ClockIn}
          />
          <StatsCard
            title={t("leaves.avgClockOut")}
            time={
              attendanceData?.statistics.avgClockOut
                ? moment(attendanceData?.statistics.avgClockOut).format("LT")
                : "--"
            }
            Icon={ClockOut}
          />
          <StatsCard
            title={t("leaves.avgWorkingHours")}
            time={
              attendanceData?.statistics?.avgWorkingHours
                ?.toFixed(2)
                ?.toString() ?? ""
            }
            Icon={Clock}
          />
          <StatsCard
            title={t("leaves.absentLeaves")}
            time={attendanceData?.statistics?.totalAbsents?.toString() ?? ""}
            Icon={Person}
          />
        </div>
        <BasicTable
          customHeaderStyles="pr-2"
          columns={attendanceColumns}
          hideActions
          data={attendanceData?.result ?? []}
          onDelete={(values) => {
            console.log(values);
          }}
          onEdit={(values) => {
            console.log(values);
          }}
          onView={(values) => {
            console.log(values);
          }}
          onRowClick={(values) => {
            console.log(values);
          }}
          onFilterChange={handleFilterChange}
          isLoading={isFetchingAttendance}
          isFetching={isFetchingAttendance}
        />
      </div>
    </div>
  );
};
