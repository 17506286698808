import React from "react";
import { useTranslation } from "react-i18next";

export const HoursCell = ({ value }: any) => {
  const { t } = useTranslation();
  return (
    <div>
      {value
        ? Math.round((value + Number.EPSILON) * 1000) / 1000 +
          " " +
          t("common.hours")
        : "0"}
    </div>
  );
};
