import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LineChart } from "../../../components/UI/LineChart";
import { PieChartCard } from "../../../components/UI/PieChartCard";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Caret } from "../../../assets/ui/chevron.svg";
import { BasicTable } from "../../../components/UI/Table";
import { getEmployeeColumns } from "../../../data/columns";
import ReactPaginate from "react-paginate";
import { StatusCard } from "../../../components/StatusCard";
import { useGetMyEmployeesQuery } from "../../../app/services/employees";
import { SelectComponent } from "../../../components/UI/Select";
import { ClockType, useClockResponses } from "../../../hooks/useClockResponses";
import { useTimer } from "../../../hooks/useTimer";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { Timer } from "../../../components/attandance/Timer";
import { useGetAttendanceStatusQuery } from "../../../app/services/attendance";
import { TEmployee } from "../../../app/types/employee.type";
import i18n from "../../../app/i18n";
import { useNavigate } from "react-router-dom";
import { hasAllowedLocation } from "../../../utils/utils";

export const Home = () => {
  const clock = useSelector((state: RootState) => state.attendance.clock);
  const startTime = useSelector(
    (state: RootState) => state.attendance.startTime
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [clockType, setClockType] = useState<ClockType>("none");

  const [searchType, setSearchType] = useState<keyof TEmployee>("fullName");
  const [searchValue, setSearchValue] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState<
    string | undefined
  >();

  const {
    runQuery,
    running,
    isCILoading,
    isCOLoading,
    isBILoading,
    isBOLoading,
  } = useClockResponses({
    type: clockType,
  });

  const { isMyTodaysTimeLoading } = useTimer();

  // useEffect(() => {
  //   hasAllowedLocation();
  // }, []);

  useEffect(() => {
    runQuery();
  }, [clockType]);

  const { data: employees } = useGetMyEmployeesQuery({
    page: currentPage,
    search: searchType,
    searchVal: searchValue,
  });

  const { data: allEmployees } = useGetMyEmployeesQuery({
    page: 1,
    limit: 100000000000,
  });

  const navigate = useNavigate();

  const { data: attendanceStatus, isLoading: isAttendanceStatusLoading } =
    useGetAttendanceStatusQuery();

  console.log(attendanceStatus);

  const { t } = useTranslation();
  const handleFilterChange = (filter: any) => {
    console.log("filter", filter);
  };
  const employeeColumns = getEmployeeColumns(t);
  const handlePageChange = (e: any) => {
    if (e.selected < 0) return;
    else if (e.selected < currentPage) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setSelectedEmployee(allEmployees?.result[0]?.user?._id);
  }, [allEmployees]);

  const handleSearch = (e: any) => {
    setSearchType("fullName");
    setSearchValue(e.target.value);
  };

  const handleEmployeesFilter = (option: any) => {
    setSearchType("roles");
    setSearchValue(option.value);
  };

  function diff_minutes(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff)) + "min";
  }

  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <>
      <section>
        <div className="flex flex-wrap justify-center lg:justify-between">
          <PieChartCard
            title={t("home.pieChartCard.totalEmployees")}
            borderColor="border-t-[#2929CB]"
            pieColor={"#2929CB"}
            portion={attendanceStatus?.totalEmployees || 0}
            total={null}
            data={[{ name: "total", value: attendanceStatus?.totalEmployees }]}
            isLoading={isAttendanceStatusLoading}
          />

          <PieChartCard
            title={t("home.pieChartCard.employeesAttended")}
            borderColor="border-t-[#0BB75C]"
            total={attendanceStatus?.totalEmployees || 0}
            portion={attendanceStatus?.totalAttends || 0}
            pieColor={"#0BB75C"}
            data={[
              { name: "attended", value: attendanceStatus?.totalAttends },
              {
                name: "remained",
                value:
                  attendanceStatus?.totalEmployees !== undefined
                    ? attendanceStatus?.totalEmployees -
                      attendanceStatus?.totalAttends
                    : 0,
              },
            ]}
            isLoading={isAttendanceStatusLoading}
          />
          <PieChartCard
            title={t("home.pieChartCard.employeeLate")}
            borderColor="border-t-[#F3AE39]"
            total={attendanceStatus?.totalEmployees || 0}
            portion={attendanceStatus?.lates || 0}
            pieColor={"#F3AE39"}
            data={[
              { name: "lates", value: attendanceStatus?.lates },
              {
                name: "remained",
                value:
                  attendanceStatus?.totalEmployees !== undefined
                    ? attendanceStatus?.totalEmployees - attendanceStatus?.lates
                    : 0,
              },
            ]}
            isLoading={isAttendanceStatusLoading}
          />
          <PieChartCard
            title={t("home.pieChartCard.employeesAbsent")}
            borderColor="border-t-[#E15C70]"
            total={attendanceStatus?.totalEmployees || 0}
            portion={attendanceStatus?.totalAbsents || 0}
            pieColor={"#E15C70"}
            data={[
              { name: "lates", value: attendanceStatus?.totalAbsents },
              {
                name: "remained",
                value:
                  attendanceStatus?.totalEmployees !== undefined
                    ? attendanceStatus?.totalEmployees -
                      attendanceStatus?.totalAbsents
                    : 0,
              },
            ]}
            isLoading={isAttendanceStatusLoading}
          />
        </div>
      </section>
      <section className="my-5">
        <span className="pb-5 hidden lg:block font-bold">
          {t("common.employeeSpecific")}
        </span>
        <div className="flex  justify-center lg:justify-between gap-8 flex-wrap lg:flex-nowrap">
          <StatusCard
            subtitle={t("home.pieChartCard.attendance")}
            subtitleData={attendanceStatus?.attendances || 0}
            isLoading={isAttendanceStatusLoading}
          />
          <StatusCard
            subtitle={t("home.pieChartCard.late")}
            subtitleData={attendanceStatus?.lates || 0}
            isLoading={isAttendanceStatusLoading}
          />
          <StatusCard
            subtitle={t("home.pieChartCard.overTime")}
            subtitleData={attendanceStatus?.workedOverTime || 0}
            isLoading={isAttendanceStatusLoading}
          />
          <StatusCard
            subtitle={t("home.pieChartCard.shortTime")}
            subtitleData={attendanceStatus?.isShortTime || 0}
            isLoading={isAttendanceStatusLoading}
          />
        </div>
      </section>
      <section>
        <div className="flex my-20 flex-wrap lg:flex-nowrap justify-center lg:justify-between items-start gap-14 h-full">
          <div className="w-8/12 text-left rtl:text-right flex flex-col gap-10 px-8 py-4  h-auto box_shadow_rb rounded-3xl">
            <div className="w-full flex items-center justify-between">
              <span className="ml-6 rtl:mr-6  text-xl pt-3 font-semibold">
                {t("work_report")}
              </span>
              <SelectComponent
                className="max-w-fit"
                defaultValue={allEmployees?.result[0]?.user?._id}
                options={allEmployees?.result?.map((el) => {
                  return { label: el.user?.fullName, value: el.user?._id };
                })}
                handleChange={(selected) => setSelectedEmployee(selected.value)}
              />
            </div>
            <LineChart selectedEmployee={selectedEmployee} />
          </div>
          <div className="flex flex-col gap-12 items-center h-full justify-center flex-1 box_shadow_rb rounded-3xl p-8">
            <span className="text-2xl font-semibold">
              {t("todays_attendance")}
            </span>
            <hr className="w-80 text-secondary opacity-10" />
            {isMyTodaysTimeLoading ? (
              <div className="w-32 h-14 bg-slate-200 rounded animate-pulse"></div>
            ) : (
              <Timer running={running} startTime={startTime} />
            )}

            <div className="flex flex-col gap-5 bg-secondary bg-opacity-10 p-5 rounded-2xl w-full">
              <div className="flex justify-between gap-36">
                <div className="flex gap-2 items-center">
                  <span className="w-4 h-4 bg-primary inline-block rounded-xl"></span>
                  <span className="min-w-max">{t("clocked_in")}</span>
                </div>
                <span className="font-bold text-right w-[6rem]">
                  {isMyTodaysTimeLoading ? (
                    <div className="w-16 h-5 bg-slate-200 rounded animate-pulse float-right"></div>
                  ) : (
                    <>
                      {clock?.clockIn
                        ? new Date(clock?.clockIn).toLocaleTimeString()
                        : "--:-- AM"}
                    </>
                  )}
                </span>
              </div>
              <div className="flex justify-between gap-36">
                <div className="flex gap-2 items-center">
                  <span className="w-4 h-4 bg-secondary inline-block rounded-xl "></span>
                  <span>{t("break")}</span>
                </div>
                <span className="font-bold text-right w-[6rem]">
                  {isMyTodaysTimeLoading ? (
                    <div className="w-5 h-5 bg-slate-200 rounded animate-pulse float-right"></div>
                  ) : (
                    <>
                      {clock?.clockIn && clock?.breakIn && clock?.breakOut
                        ? diff_minutes(
                            new Date(clock?.breakIn),
                            new Date(clock?.breakOut)
                          )
                        : "--"}
                    </>
                  )}
                </span>
              </div>
              <div className="flex justify-between gap-36">
                <div className="flex gap-2 items-center min-w-max">
                  <span className="w-4 h-4 bg-warning inline-block rounded-xl"></span>
                  <span>{t("clocked_out")}</span>
                </div>
                <span className="font-bold text-right w-[6rem]">
                  {isMyTodaysTimeLoading ? (
                    <div className="w-16 h-5 bg-slate-200 rounded animate-pulse float-right"></div>
                  ) : (
                    <span>
                      {clock?.clockOut
                        ? new Date(clock?.clockOut).toLocaleTimeString()
                        : "--:-- AM"}
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div className="flex  gap-8 justify-end w-full">
              <button
                disabled={
                  clock?.clockOut ||
                  isCILoading ||
                  isCOLoading ||
                  isBILoading ||
                  isBOLoading
                    ? true
                    : false
                }
                onClick={() => {
                  // since we have checked user cannot break in before
                  //passing the conditions before it, therefore we can use this condition here

                  if (!clock?.clockIn) {
                    setClockType("clockIn");
                  } else {
                    setClockType("clockOut");
                  }
                }}
                className={`bg-primary ${
                  clock?.clockOut && "bg-secondary"
                } text-white py-2 px-18 rounded-lg flex-1`}
              >
                {!clock?.clockIn && !clock?.breakIn && t("clock_in")}

                {clock?.clockIn && !clock?.clockOut && t("clock_out")}
                {clock?.clockIn && clock?.clockOut && "-----"}
              </button>
              <button
                disabled={
                  clock?.breakOut ||
                  clock?.clockOut ||
                  !clock?.clockIn ||
                  isCILoading ||
                  isCOLoading ||
                  isBILoading ||
                  isBOLoading
                    ? true
                    : false
                }
                onClick={() => {
                  clock?.breakIn
                    ? setClockType("breakOut")
                    : setClockType("breakIn");
                }}
                className=" text-secondary py-2  rounded-lg box_shadow_rb p-4"
              >
                {clock?.breakIn && !clock?.breakOut && t("breakIn")}
                {!clock?.breakIn && "+ " + t("break")}
                {clock?.breakIn && clock?.breakOut && "-----"}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="flex w-full items-center justify-between">
        <SelectComponent
          className="w-[150px]"
          defaultValue=""
          options={[
            {
              label: "All",
              value: "",
            },
            {
              label: "Managers",
              value: "MANAGER",
            },
            {
              label: "Employees",
              value: "EMPLOYEE",
            },
          ]}
          handleChange={handleEmployeesFilter}
        />

        <div className="relative border border-secondary border-opacity-20 rounded-xl p-2">
          <input
            className="w-full lg:min-w-[500px] outline-non border-none"
            placeholder={t("search_for_employees")}
            // value={searchValue}
            onChange={handleSearch}
          />
          <Search
            className={`absolute ${
              dir === "rtl" ? "left-4" : "right-4"
            }  bottom-5 fill-secondary stroke-secondary`}
          />
        </div>
      </div>

      <BasicTable
        columns={employeeColumns}
        data={employees?.result ?? []}
        onDelete={(values) => {
          console.log(values);
        }}
        onEdit={(values) => {
          console.log(values);
        }}
        onView={(values) => {
          console.log(values);
        }}
        onRowClick={(values) => {
          navigate(`people/${values["user._id"]}#attendance`);
        }}
        rowStyle="cursor-pointer"
        onFilterChange={handleFilterChange}
        isLoading={false}
        isFetching={false}
      />
      <ReactPaginate
        previousLabel={
          <Caret
            className={`${dir === "rtl" ? "rotate-180" : ""}`}
            direction="left"
            type="caret"
          />
        }
        nextLabel={
          <Caret
            className={`${dir === "rtl" ? "" : "rotate-180"}`}
            direction="right"
            type="caret"
          />
        }
        pageCount={employees ? employees?.count / employees?.limit : 0}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={
          "pagination self-center mt-8 max-w-[60vw] lg:max-w-full"
        }
        activeClassName={"active"}
      />
      <section>
        <div></div>
      </section>
    </>
  );
};
