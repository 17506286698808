import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ReactComponent as Bin } from "../../../assets/icons/bin.svg";
import Modal from "../../../components/UI/Modal";

type Tprops = {
  name: string;
  type: string;
  selected: boolean;
  setSelected: () => void;
  removeSchedule: () => void;
};

export const ScheduleCard = (props: Tprops) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleDelete = () => {
    setIsDeleteOpen(false);

    props.removeSchedule();
    toast(t("common.success") as string, {
      type: "success",
      autoClose: 2000,
      position: "top-center",
    });
  };
  const { t } = useTranslation();
  return (
    <div
      className={` min-h-[6rem] flex justify-between  items-center -ml-3 gap-2 p-5 border-b border-b-tertiary
       transition-all ${props.selected && "border-l-4 border-primary"}`}
    >
      <div
        onClick={props.setSelected}
        className="flex flex-col gap-2 cursor-pointer"
      >
        <span className="font-normal">{props.name}</span>
        <span className="text-secondary text-sm">{props.type}</span>
      </div>
      <Bin
        onClick={() => setIsDeleteOpen(true)}
        className="w-5 h-5 cursor-pointer fill-red-600"
      />
      <Modal
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        hasFooter={true}
        title={t("schedule.modal.titleDelete")}
        onConfirm={handleDelete}
        isLoading={false}
      >
        <h3 className="text-xl py-4 pb-5 text-description">
          {t("schedule.modal.deleteTitle")}
        </h3>
      </Modal>
    </div>
  );
};
