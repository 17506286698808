import React from "react";

export const DateCell = ({ value, row }: any) => {
  return (
    <div>
      {new Date(value).toDateString()} -{" "}
      {new Date(row.original.endDate).toDateString()}
    </div>
  );
};
