import { baseApi } from "./baseApi";
import { LoginPayload, LoginResponse } from "../types/auth.type";

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (body) => ({
        url: "/auth/manager/login",
        method: "POST",
        body,
      }),
    }),
    requestReset: builder.mutation<void, { email: string }>({
      query: (body) => ({
        url: "/auth/request-reset",
        method: "POST",
        body,
      }),
    }),
    sendVerification: builder.mutation<LoginResponse, { email: string }>({
      query: (body) => ({
        url: "/auth/send-verification",
        method: "POST",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      void,
      { password: string; id: string; token: string }
    >({
      query: (body) => ({
        url: `/auth/reset-password/${body.id}/${body.token}`,
        method: "POST",
        body: {
          password: body.password,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRequestResetMutation,
  useResetPasswordMutation,
  useSendVerificationMutation,
} = authApi;
