import { baseApi } from "./baseApi";
import queryString from "query-string";
import { PaginatedResponse, Pagination } from "../../utils/utils";
import {
  AddLocationPayload,
  EditLocationPayload,
  TLocation,
} from "../types/location.type";

const locationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<
      PaginatedResponse<TLocation>,
      Pagination<TLocation>
    >({
      query: (params) => ({
        url: `/location/me?${queryString.stringify(params)}`,
      }),
      providesTags: ["Location"],
    }),

    addLocation: builder.mutation<TLocation, AddLocationPayload>({
      query: (payload) => ({
        url: "/location",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Location"],
    }),

    editLocation: builder.mutation<TLocation, EditLocationPayload>({
      query: (payload) => ({
        url: `/location/${payload._id}`,
        method: "PATCH",
        body: {
          title: payload.title,
          description: payload.description,
          radius: payload.radius,
          coordinates: payload.coordinates,
        },
      }),
      invalidatesTags: ["Location"],
    }),

    deleteLocation: builder.mutation<void, string>({
      query: (id) => ({
        url: `/location/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Location"],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
} = locationApi;
