import React, { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../app/services/auth";
import { Spinner } from "../../components/UI/Spinner";
import logo from "../../assets/images/rectangle.png";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { JWTDecoded, LoginPayload } from "../../app/types/auth.type";
import i18n from "../../app/i18n";
import "./../../App.css";
export const Login = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [login, loginResult] = useLoginMutation();
  const {
    register,
    handleSubmit,
    // watch,
    // formState: { errors },
  } = useForm<LoginPayload>();
  const onSubmit = (data: LoginPayload) => {
    login(data);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (loginResult.isLoading) {
      setIsLoading(true);
    }
    if (loginResult.isError) {
      toast.error("Invalid credentials", {
        position: "top-center",
        autoClose: 1500,
      });
      loginResult.reset();
    }

    if (loginResult.isSuccess) {
      const decoded: JWTDecoded = jwtDecode(loginResult.data.token);
      toast.success(`Welcome ${decoded.fullName}!`, {
        position: "top-center",
        autoClose: 1500,
      });
      localStorage.setItem("fullName", decoded.fullName);
      localStorage.setItem("avatar", decoded.avatar);

      localStorage.setItem("token", loginResult.data?.token);

      navigate("/dashboard");
      loginResult.reset();
    }
  }, [loginResult, navigate]);
  const { t } = useTranslation();

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);

    // document.body.dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();

    localStorage.setItem("lng", lng);
  };

  // fix for kurdish rtl issue
  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <div className={`h-screen ${dir === "rtl" ? "dir-rtl" : "dir-ltr"}`}>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md lg:max-w-lg w-full space-y-10">
          <img src={logo} alt="logo" className="mx-auto h-16 w-auto" />

          <h1 className="text-center font-bold text-2xl">
            {t("sign_in_to_hr")}
          </h1>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm  space-y-12">
              <div>
                <label htmlFor="email-address" className="font-light text-sm">
                  {t("email")}
                </label>
                <input
                  {...register("email")}
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none mt-2  rounded-none relative block w-full px-3 py-2 border border-secondary border-opacity-40  placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:z-10 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="password" className="font-light text-sm pb-1">
                  {t("password")}
                </label>
                <input
                  {...register("password")}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none mt-2 border border-secondary border-opacity-40 rounded-none relative block w-full px-3 py-2 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between ">
              <div className="text-sm">
                <span
                  onClick={() => navigate("/request-reset")}
                  className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline cursor-pointer"
                >
                  {t("forgotPassword")}
                </span>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group mt-5 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span> */}
                {loginResult.isLoading ? <Spinner /> : t("login")}
              </button>
            </div>
          </form>
          <div className="flex justify-center items-center gap-4">
            <button
              onClick={() => {
                handleLanguageChange("en");
              }}
            >
              English
            </button>
            <button
              onClick={() => {
                handleLanguageChange("ku");
              }}
            >
              کوردی
            </button>
            <button
              onClick={() => {
                handleLanguageChange("ar");
              }}
            >
              عربی
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
