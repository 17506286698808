import i18n from "../app/i18n";
import { RequestStatus } from "../app/types/user-request.type";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export type Pagination<T> = {
  search?: keyof T;
  searchVal?: string;
  page?: number;
  limit?: number;
  sort?: string;
  orderBy?: string;
  start?: string;
  end?: string;
};

export type PaginatedResponse<T> = {
  result: T[];
  count: number;
  page: number;
  limit: number;
};

export function hasAllowedLocation() {
  if (navigator.geolocation) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        if (permissionStatus.state === "denied") {
          alert("Please allow location access.");
          window.location.href = "app-settings:location";
        } else {
          // navigator.geolocation.getCurrentPosition(success, error);
        }
      });
  } else {
    alert("Geolocation is not supported in your browser.");
  }
}

export function getValueFromLang(errorMessage: any): string {
  // If the "lang" key exists in the localStorage, use that.
  return errorMessage ? errorMessage[i18n.language] ?? "" : "";
}

export const getStatusTypeColor = (leaveType: string) => {
  switch (leaveType) {
    case RequestStatus.Declined:
      return "bg-red-50 text-red-400  text-red-400";

    case RequestStatus.Pending:
      return "bg-yellow-50 text-yellow-400  text-yellow-400";

    case RequestStatus.Approved:
      return "bg-green-50 text-green-400  text-green-400";

    default:
      return "bg-gray-50 text-gray-400  text-gray-400";
  }
};

type Languages = {
  en: string;
  ar: string;
  ku: string;
};
export type ApiError = {
  data: { errorMessage: Languages };
};

export const extractErrMsg = (errObj: ApiError) => {
  return errObj.data.errorMessage[i18n.language as keyof Languages];
};
