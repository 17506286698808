import React from "react";

export const NameCell = (data: any) => {
  return (
    <div className="flex justify-start items-center">
      <img
        src={`${data.row?.original?.user?.avatar}`}
        alt=""
        className="w-16 h-16 rounded-full mr-2 rtl:ml-2"
      />
      <span>{data.value} </span>
    </div>
  );
};
