import moment from "moment";

export const DateWithTimeCell = ({ value, row }: any) => {
  return (
    <div>
      {value
        ? moment(value).format("l") + " " + moment(value).format("LT")
        : "__"}
    </div>
  );
};
