import React from "react";
import { PieChart, Pie } from "recharts";

type Tprops = {
  title: string;
  data: any;
  borderColor: string;
  total: number | null;
  portion: number;
  pieColor: string;
  isLoading?: boolean;
};

export const PieChartCard = (props: Tprops) => {
  return (
    <div
      className={`box_shadow_rb flex flex-col items-center justify-center rounded-2xl border-t-4 min-w-[310px] my-4 ${props.borderColor} p-5`}
    >
      <span className="font-semibold">{props.title}</span>
      <div className="relative">
        {props.isLoading ? (
          <div className="w-[160px] h-[160px] my-5 mx-auto rounded-full bg-slate-200 animate-pulse flex justify-center items-center">
            <div className="w-[100px] h-[100px] rounded-full bg-white"></div>
          </div>
        ) : (
          <PieChart width={200} height={200}>
            <Pie
              dataKey="value"
              data={props.data}
              cx="50%"
              cy="50%"
              innerRadius={50}
              outerRadius={80}
              paddingAngle={0}
              stroke="#fff"
              fill={props.pieColor}
            />
          </PieChart>
        )}

        <span className="absolute z-50 top-[50%] left-[50%] text-xl font-bold transform -translate-x-1/2 -translate-y-1/2">
          {props.isLoading ? (
            <div className="w-14 h-5 bg-slate-200 animate-pulse"></div>
          ) : props.total ? (
            `${props.portion}/${props.total}`
          ) : (
            props.portion
          )}
        </span>
      </div>
    </div>
  );
};

//  <div className="flex flex-col justify-start gap-4">
//    <span className="text-lg">{props.subtitleData}</span>
//    <span className="text-sm text-secondary">{props.subtitle}</span>
//  </div>;
