import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { useGetTodaysTimeQuery } from "../app/services/attendance";
import { useDispatch } from "react-redux";
import {
  changeClock,
  setRunning,
  setStartTime,
} from "../app/slices/attendanceSlices";

export const useTimer = () => {
  const dispatch = useDispatch();
  const {
    data: myTodaysTime,
    isError: isMyTodaysTimeError,
    isLoading: isMyTodaysTimeLoading,
  } = useGetTodaysTimeQuery();


  //get todays time
  useEffect(() => {
    if (isMyTodaysTimeError) {
      console.log("error");
    }
    if (myTodaysTime) {
      if (
        myTodaysTime.clockIn &&
        myTodaysTime.clockOut &&
        myTodaysTime.breakIn &&
        myTodaysTime.breakOut
      ) {
        const startTime: number =
          new Date(myTodaysTime.breakIn).getTime() -
          new Date(myTodaysTime.clockIn).getTime() +
          (new Date(myTodaysTime.clockOut).getTime() -
            new Date(myTodaysTime.breakOut).getTime());
        dispatch(setRunning(false));
        dispatch(setStartTime(startTime));
      } else if (
        myTodaysTime.clockIn &&
        myTodaysTime.breakIn &&
        myTodaysTime.breakOut
      ) {
        const startTime: number =
          new Date(myTodaysTime.breakIn).getTime() -
          new Date(myTodaysTime.clockIn).getTime() +
          (new Date().getTime() - new Date(myTodaysTime.breakOut).getTime());
        dispatch(setRunning(true));
        dispatch(setStartTime(startTime));
      } else if (myTodaysTime.clockIn && myTodaysTime.breakIn) {
        const startTime: number =
          new Date(myTodaysTime.breakIn).getTime() -
          new Date(myTodaysTime.clockIn).getTime();

        dispatch(setRunning(false));
        dispatch(setStartTime(startTime));
      } else if (myTodaysTime.clockIn && myTodaysTime.clockOut) {
        const startTime: number =
          new Date(myTodaysTime.clockOut).getTime() -
          new Date(myTodaysTime.clockIn).getTime();

        dispatch(setRunning(false));
        dispatch(setStartTime(startTime));
      } else if (myTodaysTime.clockIn) {
        const startTime: number =
          new Date().getTime() - new Date(myTodaysTime.clockIn).getTime();

        dispatch(setRunning(true));
        dispatch(setStartTime(startTime));
      }
    }
    if (myTodaysTime) {
      // console.log(typeof myTodaysTime.clockIn);
      dispatch(
        changeClock({
          clockIn: myTodaysTime.clockIn,
          clockOut: myTodaysTime.clockOut,
          breakIn: myTodaysTime.breakIn,
          breakOut: myTodaysTime.breakOut,
        })
      );
    }
  }, [myTodaysTime]);

  return { isMyTodaysTimeLoading };
};
