import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
// import { privateRoutes } from "./app/private-routes";
import { ProtectedRoutes } from "./app/ProtectRoutes";
import { Login } from "./routes/auth/Login";
import { NotFound } from "./routes/NotFound";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "./routes/dashboard/Container";
import i18n from "./app/i18n";
import { RequestReset } from "./routes/auth/RequestReset";
import { ResetPassword } from "./routes/auth/ResetPassword";

function App() {
  document.body.dir = i18n.language === "ku" ? "rtl" : i18n.dir();

  return (
    <div className={`lang-${i18n.language}`}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/request-reset" element={<RequestReset />} />
          <Route
            path="/reset-password/:id/:token"
            element={<ResetPassword />}
          />
          <Route path="/" element={<Navigate to={"/dashboard"} replace />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard/*" element={<Container />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
