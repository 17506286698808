import React, { useState } from "react";
import { BasicTable } from "../../../components/UI/Table";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Caret } from "../../../assets/ui/chevron.svg";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { getEmployeeColumns } from "../../../data/columns";
import { SelectComponent } from "../../../components/UI/Select";
import Button from "../../../components/UI/Button";
import { AddPeople } from "./AddPeople";
import { useGetMyEmployeesQuery } from "../../../app/services/employees";
import { TEmployee } from "../../../app/types/employee.type";
import i18n from "../../../app/i18n";
import { useNavigate } from "react-router-dom";

export const People = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [searchType, setSearchType] = useState<keyof TEmployee>("roles");
  const { data: employees } = useGetMyEmployeesQuery(
    {
      page: currentPage,
      search: searchType,
      searchVal,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleFilterChange = (filter: any) => {
    console.log("filter", filter);
  };
  const employeeColumns = getEmployeeColumns(t);
  const handlePageChange = (e: any) => {
    if (e.selected < 0) return;
    else if (e.selected < currentPage) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <>
      <div className="flex w-full justify-between items-center">
        <SelectComponent
          className="max-w-[150px]"
          defaultValue={"all"}
          handleChange={(selected) => {
            setSearchType("roles");
            setSearchVal(selected.value === "all" ? "" : selected.value);
          }}
          options={[
            {
              label: "Any role",
              value: "all",
            },
            {
              label: "Manager",
              value: "MANAGER",
            },
            {
              label: "Employee",
              value: "EMPLOYEE",
            },
          ]}
        />
        <div className="flex justify-between gap-8">
          <div className="relative border border-secondary border-opacity-20 rounded-xl p-2">
            <input
              className="w-full lg:min-w-[44rem] outline-none border-none"
              placeholder={t("search_for_employees")}
              onChange={(e) => {
                setSearchType("fullName");
                setSearchVal(e.target.value);
              }}
            />
            <Search
              className={`absolute ${
                dir === "rtl" ? "left-4" : "right-4"
              } bottom-5 fill-secondary stroke-secondary`}
            />
          </div>
          <Button
            onClick={() => setIsAddModalOpen(!isAddModalOpen)}
            className="min-w-[13rem]"
            variant="primary"
          >
            {t("common.add")}
          </Button>
        </div>
      </div>

      <BasicTable
        columns={employeeColumns}
        data={employees?.result ?? []}
        onDelete={(values) => {
          console.log(values);
        }}
        onEdit={(values) => {
          console.log(values);
        }}
        onView={(values) => {
          console.log(values);
        }}
        onRowClick={(values) => {
          navigate(`${values["user._id"]}#attendance`);
        }}
        onFilterChange={handleFilterChange}
        isLoading={false}
        isFetching={false}
        headerClassName={"pl-5"}
        rowStyle={"cursor-pointer"}
      />
      <ReactPaginate
        previousLabel={
          <Caret
            className={`${dir === "rtl" ? "rotate-180" : ""}`}
            direction="left"
            type="caret"
          />
        }
        nextLabel={
          <Caret
            className={`${dir === "rtl" ? "" : "rotate-180"}`}
            direction="right"
            type="caret"
          />
        }
        pageCount={employees ? employees.count / employees.limit : 0}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={
          "pagination self-center mt-8 max-w-[60vw] lg:max-w-full"
        }
        activeClassName={"active"}
      />

      <AddPeople
        setIsAddModalOpen={() => setIsAddModalOpen(!isAddModalOpen)}
        isAddModalOpen={isAddModalOpen}
      />
    </>
  );
};
