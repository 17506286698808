export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validate password
 * Rules:
 * Password should at least be 8 characters,
 * contains at least one uppercase letter,
 */
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

/**
 * Disallow anything other than letters, but allow spaces
 */
export const lettersOnlyRegex = /^[a-zA-Z ]*$/;

// phone number regex
export const phoneNumberRegex = /^\d{10}$/;
