import React, { useState } from "react";
import { Pagination } from "../../../components/Pagination";
import { Card } from "../../../components/UI/Card";

export const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  console.log(currentPage);
  return (
    <>
      <div className="flex justify-around flex-wrap gap-5">
        <Card></Card>
        <Card></Card>
        <Card></Card>
        <Card></Card>
      </div>
      <Pagination
        itemsPerPage={10}
        totalItems={12}
        getCurrentPage={(currentPage) => setCurrentPage(currentPage)}
      />
    </>
  );
};
