import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  LineChart as Chart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGetAttendanceWorkReportQuery } from "../../app/services/attendance";
import CustomCalendar from "./CustomCalendar";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const LineChart = (props: { selectedEmployee: string | undefined }) => {
  let startDate = new Date();
  let pastDate = startDate.getDate() - 7;
  startDate.setDate(pastDate);

  const [workReportRange, setWorkReportRange] = useState({
    start: startDate.toISOString(),
    end: new Date().toISOString(),
  });

  const { data } = useGetAttendanceWorkReportQuery(
    {
      ...workReportRange,
      search: "fullName",
      searchVal: props?.selectedEmployee,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [chartData, setChartData] = useState<
    { _id: string; workedHours: number }[] | []
  >([]);
  const { t } = useTranslation();

  useEffect(() => {
    setChartData(
      data?.map((el: { _id: string; workedHours: number }) => {
        return {
          _id: moment(el._id).format("MM-DD"),
          workedHours: el.workedHours,
        };
      })
    );
  }, [data]);

  const handleDateConfirm = (date: Date[]) => {
    setWorkReportRange({
      start: date[0].toISOString(),
      end: date[1].toISOString(),
    });
  };

  return (
    <>
      <CustomCalendar
        onConfirm={(date) => handleDateConfirm(date)}
        buttonClassName="w-full"
        initialState={[startDate, new Date()]}
      />
      <ResponsiveContainer width="100%" height="75%" aspect={3.27}>
        <AreaChart
          width={400}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis
            padding={{ left: 50 }}
            axisLine={false}
            tickLine={false}
            dataKey="_id"
          />
          <YAxis padding={{ bottom: 20 }} axisLine={false} tickLine={false} />
          <Tooltip
            content={(val) => {
              return (
                <div className="bg-primary text-white p-3 rounded-lg">
                  {val.payload && val.payload.length > 0
                    ? Math.round(val.payload[0]?.payload?.workedHours * 100) /
                        100 +
                      " " +
                      t("common.hours")
                    : 0 + " " + t("common.hours")}
                </div>
              );
            }}
          />
          <Area
            type="monotone"
            dataKey="workedHours"
            stroke="#3D3DE2"
            fillOpacity={0.2}
            fill="#3D3DE2"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};
