import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ScheduleCard } from "./ScheduleCard";
import { FormSection } from "./FormSection";

import {
  useDeleteScheduleMutation,
  useGetSchedulesQuery,
} from "../../../app/services/schedule/schedule";
import { toast } from "react-toastify";
import { TTimeTable } from "../../../app/types/timeTable.type";
import i18n from "../../../app/i18n";

export const Schedules = () => {
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState<string>("");

  const { data: schedulesData, isFetching } = useGetSchedulesQuery(
    {
      search: "name",
      searchVal,
      limit: 100,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [deleteSchedule, deleteResult] = useDeleteScheduleMutation();

  const [selected, setSelected] = React.useState<TTimeTable | undefined>();
  const [schedules, setSchedules] = React.useState<TTimeTable[] | undefined>();

  useEffect(() => {
    setSchedules(schedulesData?.result);
  }, [schedulesData]);
  useEffect(() => {
    if (deleteResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
    }
  }, [deleteResult, t]);

  useEffect(() => {
    if (schedules) {
      !selected && setSelected(schedules[0]);
      schedules?.length < 1 && setSelected(undefined);
    }
  }, [schedules, selected]);

  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <section className="flex w-full justify-start gap-10 -mt-5">
      <div className="border-r-2 rtl:border-l-2 rtl:border-r-0 min-h-screen pr-3 rtl:pl-3 rtl:pr-0 pt-2 border-tertiary flex flex-col gap-10">
        <div className="flex gap-2 -ml-3 rtl:-mr-3 rtl:ml-0">
          <div className="relative border border-secondary border-opacity-20 rounded-xl p-2">
            <input
              onChange={(e) => setSearchVal(e.target.value)}
              className="w-full lg:min-w-[296px] outline-none border-none"
              placeholder={t("search_for_schedules")}
            />
            <Search
              className={`absolute ${
                dir === "rtl" ? "left-4" : "right-4"
              } bottom-5`}
            />
          </div>
          <button
            onClick={() => {
              if (
                schedules &&
                schedules.filter((el) => el.isDummy).length > 0
              ) {
                return alert("You have unsaved changes");
              }
              const randomId = `${Math.random() * 10}`;
              const dummySchedule: TTimeTable = {
                name: "untitled",
                type: "weekly",
                _id: randomId,
                createdAt: new Date(),
                isActive: false,
                updatedAt: new Date(),
                workDays: [],
                isDummy: true,
              };
              setSchedules((schedules) => {
                return schedules
                  ? [dummySchedule, ...schedules]
                  : [dummySchedule];
              });
              setSelected(dummySchedule);
            }}
            className="bg-primary rounded-lg px-6"
          >
            <Plus />
          </button>
        </div>

        <div className={`flex flex-col gap-[0.1rem] ${isFetching && "blur"}`}>
          {schedules?.map((schedule, i) => (
            <ScheduleCard
              setSelected={() => {
                if (schedules.filter((el) => el.isDummy).length > 0) {
                  return alert("You have unsaved changes");
                }
                setSelected(schedule);
              }}
              key={i}
              selected={selected?._id === schedule._id}
              name={schedule?.name}
              type={schedule?.type}
              removeSchedule={() =>
                schedule?.isDummy
                  ? setSchedules(
                      schedules.filter((el) => el._id !== schedule._id)
                    )
                  : deleteSchedule(schedule._id)
              }
            />
          ))}
        </div>
      </div>
      {selected && (
        <FormSection
          setSelected={(schedule: TTimeTable) => {
            if (schedules && schedules.filter((el) => el.isDummy).length > 0) {
              return alert("You have unsaved changes");
            }
            setSelected(schedule);
          }}
          selectedSchedule={selected}
        />
      )}
    </section>
  );
};
