import React from "react";

const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const daysString = [
  "S",
  "M",
  "T",
  "W",
  "T",
  "F",
  "S",
  "S",
  "M",
  "T",
  "W",
  "T",
  "F",
  "S",
  "S",
  "M",
  "T",
  "W",
  "T",
  "F",
  "S",
  "S",
  "M",
  "T",
  "W",
  "T",
  "F",
  "S",
  "S",
  "M",
  "T",
  "W",
];

export const TimeSheetHeader = () => {
  return (
    <div className="flex justify-center gap-[1rem] text-sm -ml-5">
      {days.map((day, index) => {
        return (
          <div className="flex flex-col justify-center items-center gap-1">
            <span className="text-secondary">{day}</span>
            <span className="text-secondary">{daysString[index]}</span>
          </div>
        );
      })}
    </div>
  );
};
