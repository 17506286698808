import * as React from "react";
import { useState, useCallback } from "react";
import { createRoot } from "react-dom/client";
import Map, { Marker, NavigationControl, Layer, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// import ControlPanel from "./control-panel";
// import Pin from "./pin";

import type { MarkerDragEvent, LngLat } from "react-map-gl";
import Button from "../../../components/UI/Button";
import { useTranslation } from "react-i18next";

export type MapViewport = {
  width?: string;
  height?: string;
  latitude: number;
  longitude: number;
  zoom: number;
};

const TOKEN =
  "pk.eyJ1IjoiYWthcm0xMyIsImEiOiJjbGNiNmVwMmEwNnljM3hwa2V6ZGwyZ2t2In0.T5CIzWMsf4RcLefS68TGAQ"; // Set your mapbox token here

type Props = {
  lat: number;
  lng: number;
  isDummy?: boolean;
  hideSave?: boolean;
  disableDrag?: boolean;
  className?: string;
  radius: number;
  onSave: () => void;
  getCoordinates: (data: {
    longitude: number;
    latitude: number;
    radius: number;
  }) => void;
};

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
  fill: "#d00",
  stroke: "none",
};

function Pin(props: { size: number }) {
  const { size = 20 } = props;

  return (
    <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
      <path d={ICON} />
    </svg>
  );
}

function MyComponent(props: Props) {
  const [viewport, setViewport] = useState<MapViewport>({
    width: "100%",
    height: "100%",
    latitude: 35.5784474,
    longitude: 45.3864838,
    zoom: 12,
  });

  const { t } = useTranslation();

  return props.lat && props.lng ? (
    <div className={`h-[650px] ${props.className}`}>
      <Map
        mapStyle="mapbox://styles/mapbox/streets-v12"
        mapboxAccessToken={TOKEN}
        // style={{ height: "600px", position: "static" }}
        onMove={({ viewState }) => {
          setViewport({
            ...viewState,
          });
        }}
        {...viewport}
      >
        <Marker
          longitude={props.lng}
          latitude={props.lat}
          anchor="bottom"
          draggable={props.disableDrag ? false : true}
          onDragEnd={(e) =>
            props.getCoordinates({
              latitude: e.lngLat.lat,
              longitude: e.lngLat.lng,
              radius: props.radius,
            })
          }
          // onDragStart={onMarkerDragStart}
          // onDrag={onMarkerDrag}
          // onDragEnd={onMarkerDragEnd}
        >
          <Pin size={20} />
        </Marker>
        <Source
          id="my-data"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: [
              {
                properties: {},
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [props.lng, props.lat],
                },
              },
            ],
          }}
        >
          <Layer
            type="circle"
            id="point"
            paint={{
              "circle-radius": props.radius,
              "circle-opacity": 0.5,
              "circle-color": "#007cbf",
            }}
          />
        </Source>

        {/* <NavigationControl /> */}
      </Map>
      <Button
        className={`z-50 ml-auto bottom-0 my-4 w-2/12 p-5 ${
          props.hideSave && "hidden"
        }`}
        variant="primary"
        type="button"
        onClick={() => props.onSave()}
      >
        {t(props.isDummy ? "common.save" : "common.update")}
      </Button>
      {/* <ControlPanel events={events} /> */}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
