import { PaginatedResponse, Pagination } from "../../utils/utils";
import {
  TEmployee,
  TEmployeesDailyAttendance,
  TUserInvitation,
} from "../types/employee.type";
import { baseApi } from "./baseApi";
import queryString from "query-string";

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyEmployees: builder.query<
      PaginatedResponse<TEmployeesDailyAttendance>,
      Pagination<TEmployee>
    >({
      query: (body) => {
        return {
          url: `/user/me?${queryString.stringify(body)}`,
        };
      },
    }),
    getMyEmployeesDailyAttendance: builder.query<
      PaginatedResponse<TEmployeesDailyAttendance>,
      Pagination<TEmployeesDailyAttendance & { month: string }>
    >({
      query: (body) => {
        return {
          url: `/user/me/monthly?${queryString.stringify(body)}`,
        };
      },
    }),

    inviteUser: builder.mutation<void, string[]>({
      query: (payload) => ({
        url: "user-invitation",
        method: "POST",
        body: {
          emails: payload,
        },
      }),
      invalidatesTags: ["UserInvitation"],
    }),
    getUserInvitations: builder.query<TUserInvitation[], undefined>({
      query: () => ({
        url: "user-invitation",
      }),
      providesTags: ["UserInvitation"],
    }),
  }),
});

export const {
  useGetMyEmployeesQuery,
  useGetMyEmployeesDailyAttendanceQuery,
  useInviteUserMutation,
  useGetUserInvitationsQuery,
} = authApi;
