export type TimetableType = "fixed" | "flexible" | "weekly";
export type TTimeTable = {
  _id: string;
  name: string;
  type: TimetableType;
  organization?: string;
  weeklyMinHours?: number;
  isDummy?: boolean;
  workDays: WorkDay[];
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export enum TimeTableTypeEnum {
  Fixed = "fixed",
  Flexible = "flexible",
  Weekly = "weekly",
}

export type TimetablePayload = {
  name?: string;
  type?: TimetableType;
  weeklyMinHours?: number;
  workDays?: WorkDay[];
};

export type WorkDay = {
  day: Day;
  from?: string;
  to?: string;
  minHours?: number;
};

export type Day =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export const dayShortantEnumMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
