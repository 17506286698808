import { useState } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  useFieldArray,
} from "react-hook-form";
import { TFunction } from "react-i18next";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import Button from "../../../components/UI/Button";
import { ScheduleFormPayload } from "./FormSection";
import { Day, daysOfWeek } from "../../../app/types/timeTable.type";

type FixedSubFormProps = {
  register: UseFormRegister<ScheduleFormPayload>;
  unregister: UseFormUnregister<ScheduleFormPayload>;
  setValue: UseFormSetValue<ScheduleFormPayload>;
  control: Control<ScheduleFormPayload>;
  t: TFunction<"translation", undefined>;
  dayShortantEnum: { label: string; value: string }[];
  days: string[];
  errors: FieldErrors<ScheduleFormPayload>;
  daysInput: string[];
};

const FlexibleForm = ({
  register,
  unregister,
  setValue,
  t,
  errors,
  dayShortantEnum,
  days,
  control,
  daysInput,
}: FixedSubFormProps) => {
  const [selectedDay, setSelectedDay] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
  ]);

  const handleSelectedDays = (value: string, index: number): void => {
    if (selectedDay.includes(value)) {
      console.log(value);
      unregister(`workDays.${index}`, { keepValue: false });
      setSelectedDay(selectedDay.filter((el) => el !== value));
    } else {
      setSelectedDay((prev) => [...prev, value].sort());
      setValue(`workDays.${index}.minHours`, 0);
      setValue(`workDays.${index}.day`, value);
    }
  };

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "workDays", // unique name for your Field Array
    }
  );
  return (
    <>
      <div className="flex items-center justify-between my-10">
        <label
          className="font-normal mr-[3.5rem] rtl:ml-[3.5rem] rtl:mr-0"
          htmlFor="name"
        >
          {t("schedule.workDays")}
        </label>

        {dayShortantEnum.map((type, i) => (
          <div
            onClick={() => {
              const day: Day = type.value as Day;
              fields.map((el) => el.day).includes(day)
                ? remove(fields.map((el) => el.day).indexOf(day))
                : append({ day });
              // handleSelectedDays(type.value, i);
              // setSelectedDay((prev) => [...prev, i]);
            }}
            key={i}
            className={`relative border transition-all border-tertiary cursor-pointer ${
              fields.map((el) => el.day).includes(type.value) &&
              "text-primary text-sm !border-primary bg-lightBlue"
            }  min-w-[5.5rem] text-center  rounded-xl py-2`}
          >
            {t(type.label)}
          </div>
        ))}
      </div>
      <div className="flex items-center justify-between my-10">
        <div className="flex flex-col">
          {fields
            .sort((a, b) => {
              const dayA = daysOfWeek.indexOf(a.day);
              const dayB = daysOfWeek.indexOf(b.day);
              return dayA - dayB;
            })
            .map((day, index) => {
              return (
                <div
                  key={day.id}
                  className="flex items-center justify-start gap-16"
                >
                  <label className="min-w-[5rem] pb-6" htmlFor="name">
                    {t(
                      dayShortantEnum.find((el) => el.value === day.day)
                        ?.value ?? ""
                    )}
                  </label>
                  <div
                    key={index}
                    className="flex items-center justify-between gap-5  my-8"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <div className="relative border border-secondary border-opacity-20 rounded-xl p-[0.3rem]">
                        <input
                          {...register(`workDays.${index}.minHours`, {
                            required: "Please do not leave empty",
                            valueAsNumber: true,
                          })}
                          type="number"
                          className="w-full lg:min-w-[10rem] outline-none border-none"
                          placeholder={t("common.minHours")}
                        />
                      </div>
                      <div className="flex flex-col gap-1 flex-wrap max-w-[15rem] mt-1">
                        {errors &&
                          errors.workDays &&
                          // @ts-ignore
                          errors.workDays[index]! && (
                            <span className="text-red-500 text-sm">
                              {/* @ts-ignore */}
                              {errors.workDays[index]?.minHours?.message}
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  <input
                    hidden
                    {...register(`workDays.${index}.day`)}
                    defaultValue={day.day}
                  />
                </div>
              );
            })}
          {/* <Button
            variant="secondary"
            className="max-w-fit ml-0 pl-0"
            onClick={() => append(2)}
          >
            {t("common.addMore")} +
          </Button> */}
        </div>
      </div>
      {/* {selectedDay.map((day, index) => {
        return (
          
        );
      })} */}
    </>
  );
};

export default FlexibleForm;
