import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import FixedSubForm from "./FixedSubForm";
import FlexibleForm from "./FlexibleForm";
import WeeklyForm from "./WeeklyForm";
import { Spinner } from "../../../components/UI/Spinner";

import {
  useAddScheduleMutation,
  useUpdateTimetableMutation,
} from "../../../app/services/schedule/schedule";
import {
  TTimeTable,
  TimeTableTypeEnum,
  TimetableType,
} from "../../../app/types/timeTable.type";

type Props = {
  selectedSchedule?: TTimeTable;
  setSelected: (schedule: TTimeTable) => void;
};

type WorkDays = {
  from?: string;
  to?: string;
  minHours?: number;
  day: string;
};

export type ScheduleFormPayload = {
  name: string;
  type: TimetableType;
  weeklyMinHours?: number;
  workDays: WorkDays[];
};

const typeEnum = [
  { label: "schedule.type.fixed", value: TimeTableTypeEnum.Fixed },
  { label: "schedule.type.flexible", value: TimeTableTypeEnum.Flexible },
  { label: "schedule.type.weekly", value: TimeTableTypeEnum.Weekly },
];

const dayShortantEnum = [
  { label: "schedule.dayShortantEnum.sun", value: "Sunday" },
  { label: "schedule.dayShortantEnum.mon", value: "Monday" },
  { label: "schedule.dayShortantEnum.tue", value: "Tuesday" },
  { label: "schedule.dayShortantEnum.wed", value: "Wednesday" },
  { label: "schedule.dayShortantEnum.thu", value: "Thursday" },
  { label: "schedule.dayShortantEnum.fri", value: "Friday" },
  { label: "schedule.dayShortantEnum.sat", value: "Saturday" },
];

const days = [
  "common.sunday",
  "common.monday",
  "common.tuesday",
  "common.wedensday",
  "common.thursday",
  "common.friday",
  "common.saturday",
];

const daysInput = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wedensday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const FormSection = (props: Props) => {
  const [selectedType, setSelectedType] = React.useState(2);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    unregister,
    watch,
    formState: { errors },
  } = useForm<ScheduleFormPayload>({
    shouldUnregister: true,
  });

  const [addSchedule, addResult] = useAddScheduleMutation();
  const [updateSchedule, updateResult] = useUpdateTimetableMutation();

  const onSubmit = (data: ScheduleFormPayload) => {
    console.log(data);

    props.selectedSchedule?.isDummy
      ? addSchedule(data)
      : updateSchedule({ ...data, id: props?.selectedSchedule?._id });
  };

  useEffect(() => {
    if (addResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });

      addResult.reset();

      props.setSelected(addResult.data);
    } else if (updateResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      updateResult.reset();
    }
  }, [addResult, t, updateResult, props]);

  useEffect(() => {
    if (props.selectedSchedule) {
      setValue("name", props.selectedSchedule.name);
      typeEnum.forEach((type, i) => {
        if (
          props.selectedSchedule &&
          type.value.includes(props.selectedSchedule.type.toLocaleLowerCase())
        ) {
          setSelectedType(i);
        }
      });

      setValue("type", props.selectedSchedule.type as TimetableType);
      setValue("weeklyMinHours", props.selectedSchedule.weeklyMinHours);
      setValue("workDays", props.selectedSchedule.workDays);
    }
  }, [props.selectedSchedule, setValue]);

  useEffect(() => {
    setValue("type", typeEnum[selectedType].value);
  }, [selectedType, setValue]);
  return (
    <div className="pt-10">
      <span className="text-2xl font-semibold">{t("schedule.name")}</span>
      <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between gap-24 my-8">
          <label htmlFor="name">{t("name")}</label>
          <div className="flex flex-col gap-1">
            <div className="relative border border-secondary border-opacity-20 rounded-xl p-3">
              <input
                defaultValue={props.selectedSchedule?.name}
                {...register("name", { required: true })}
                className="w-full lg:min-w-[40rem] outline-none border-none"
                placeholder={t("schedule.scheduleExample")}
              />
            </div>
            {errors.name && (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between gap-5 my-10">
          <label
            className="font-normal mr-[5.5rem] rtl:mr-0 rtl:ml-[5.5rem]"
            htmlFor="name"
          >
            {t("common.type")}
          </label>

          {typeEnum.map((type, i) => {
            return (
              <div
                onClick={() => setSelectedType(i)}
                key={i}
                className={`relative border border-tertiary cursor-pointer ${
                  selectedType === i &&
                  "text-primary text-sm transition-all !border-primary bg-lightBlue"
                }  min-w-[13rem] text-center  rounded-xl py-2`}
              >
                {t(type.label)}
              </div>
            );
          })}
        </div>
        {selectedType === 0 && (
          <FixedSubForm
            {...{
              register,
              unregister,
              setValue,
              t,
              errors,
              dayShortantEnum,
              days,
              daysInput,
              control,
              selectedSchedule: props.selectedSchedule,
            }}
          />
        )}

        {selectedType === 1 && (
          <FlexibleForm
            {...{
              register,
              unregister,
              control,
              setValue,
              errors,
              t,
              dayShortantEnum,
              days,
              daysInput,
              selectedSchedule: props.selectedSchedule,
            }}
          />
        )}

        {selectedType === 2 && (
          <WeeklyForm
            {...{
              register,
              unregister,
              setValue,
              t,
              errors,
              daysInput,
            }}
          />
        )}

        <button
          type="submit"
          className="bg-primary rounded-lg px-6 text-white w-full p-3 mb-20"
        >
          {addResult.isLoading || updateResult.isLoading ? (
            <Spinner />
          ) : (
            t("common.save")
          )}
        </button>
      </form>
    </div>
  );
};
