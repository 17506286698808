import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDateSlider } from "../../components/UI/CustomDateSlider";
import { BasicTable } from "../../components/UI/Table";
import { getLeavesColumns } from "../../data/columns";
import { ReactComponent as Caret } from "../../assets/ui/chevron.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import {
  useApproveRequestMutation,
  useDeclineRequestMutation,
  useGetUserRequestQuery,
  useLazyGetARequestQuery,
} from "../../app/services/user-request";
import moment from "moment";
import { ReactComponent as CalanderIcon } from "../../assets/icons/calander.svg";
import Modal from "../../components/UI/Modal";
import { toast } from "react-toastify";
import { RequestStatus } from "../../app/types/user-request.type";
import { getStatusTypeColor } from "../../utils/utils";
import { ViewContext } from "../../utils/ViewContext";
import ReactPaginate from "react-paginate";
import i18n from "../../app/i18n";

type Props = {
  userId?: string;
};
export const Leaves = (props: Props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedRequest, setSelectedRequest] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();
  const customerColumns = getLeavesColumns(t);

  const handleFilterChange = (filter: any) => {
    console.log("filter", filter);
  };

  const { isFetching: isFetchingRequests, data: requestsData } =
    useGetUserRequestQuery(
      {
        id: props.userId ?? "",
        start: moment(selectedDate).startOf("month").toISOString(),
        end: moment(selectedDate).endOf("month").toISOString(),
        page: currentPage,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [getRequestById, { data: selectedRequestData }] =
    useLazyGetARequestQuery();

  useEffect(() => {
    selectedRequest && getRequestById(selectedRequest);
  }, [selectedRequest]);

  const [approveRequest, approveResult] = useApproveRequestMutation();
  const [declineRequest, declineResult] = useDeclineRequestMutation();

  useEffect(() => {
    if (approveResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      approveResult.reset();
    } else if (approveResult.isError) {
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
      approveResult.reset();
    } else if (declineResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      declineResult.reset();
    } else if (declineResult.isError) {
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
      declineResult.reset();
    }
  }, [approveResult, declineResult]);

  const dir = i18n.dir();

  const handlePageChange = (e: any) => {
    if (e.selected < 0) return;
    else if (e.selected < currentPage) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="box_shadow_rb">
      <CustomDateSlider onChange={(date: Date) => setSelectedDate(date)} />
      <div className="body-section">
        <ViewContext.Provider
          value={[
            { openModal: () => setIsModalOpen(true) },
            { getId: (id: string) => setSelectedRequest(id) },
          ]}
        >
          <BasicTable
            hideHeaders
            columns={customerColumns}
            data={requestsData?.result ?? []}
            onDelete={(values) => {
              console.log(values);
            }}
            onEdit={(values) => {
              console.log(values);
            }}
            onView={(values) => {
              console.log(values);
            }}
            onRowClick={(values) => {
              console.log(values);
            }}
            onFilterChange={handleFilterChange}
            isLoading={isFetchingRequests}
            isFetching={isFetchingRequests}
          />
        </ViewContext.Provider>
        <ReactPaginate
          previousLabel={
            <Caret
              className={`${dir === "rtl" ? "rotate-180" : ""}`}
              direction="left"
              type="caret"
            />
          }
          nextLabel={
            <Caret
              className={`${dir === "rtl" ? "" : "rotate-180"}`}
              direction="right"
              type="caret"
            />
          }
          pageCount={
            requestsData ? requestsData?.count / requestsData?.limit : 0
          }
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={
            "pagination self-center mt-8 max-w-[60vw] lg:max-w-full"
          }
          activeClassName={"active"}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancelClick={() =>
          selectedRequestData && declineRequest(selectedRequestData._id)
        }
        hasFooter={true}
        title={t("request.modal.viewRequest")}
        onConfirm={() =>
          selectedRequestData && approveRequest(selectedRequestData?._id)
        }
        isLoading={false}
        isConfirmDisabled={
          selectedRequestData?.status === RequestStatus.Approved
        }
        isCancelDisabled={
          selectedRequestData?.status === RequestStatus.Declined
        }
        confirmText={t("common.accept")}
        cancelText={t("common.decline")}
        className="max-w-lg"
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <img
              src={selectedRequestData?.user.avatar}
              className="w-16 h-16 rounded-[50%]"
              alt="pfp"
            />
            <div className="flex flex-col">
              <span className="font-medium">
                {selectedRequestData?.user?.fullName}
              </span>
              <div className="flex items-center gap-2">
                <ClockIcon className="fill-secondary w-5 h-5" />

                <span className="text-sm text-secondary">
                  {moment(selectedRequestData?.startDate!).format(
                    "MMM Do YYYY"
                  )}{" "}
                  -{" "}
                  {moment(selectedRequestData?.endDate!).format("MMM Do YYYY")}
                </span>
              </div>
            </div>
          </div>
          <span
            className={
              "border-2 px-5 py-2 rounded-lg text-sm " +
              getStatusTypeColor(selectedRequestData?.status ?? "")
            }
          >
            {selectedRequestData?.status}
          </span>
        </div>

        <div className="flex flex-col gap-10">
          <span className="border-2 px-5 py-2 rounded-lg text-sm bg-blue-100 w-fit text-primary font-medium">
            {selectedRequestData &&
              new Date(selectedRequestData?.startDate).getDay() -
                new Date(selectedRequestData?.endDate).getDay()}{" "}
            {t("common.days")}
          </span>
          <div className="flex gap-2">
            <div className="flex gap-1">
              <CalanderIcon className="fill-secondary w-5 h-5" />
              <span className="text-secondary text-sm">
                {t("request.leaveFrom")}:{" "}
              </span>
            </div>
            <span className="font-medium text-sm">
              {moment(selectedRequestData?.startDate!).format("MMM Do")} -{" "}
              {moment(selectedRequestData?.endDate!).format("MMM Do")}
            </span>
          </div>
          {/* <span>manager</span> */}
        </div>
        <div className="flex flex-col max-w-[20rem]">
          <span className="font-medium">description</span>
          <p className="text-secondary text-sm">
            {selectedRequestData?.description}
          </p>
        </div>
      </Modal>
    </div>
  );
};
