import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useAddTimetableToUserMutation,
  useEditUserMutation,
} from "../../../app/services/users";
import { EditUserPayload, TUser } from "../../../app/types/user.type";
import { Spinner } from "../../../components/UI/Spinner";
import { SelectComponent } from "../../../components/UI/Select";
import { useGetSchedulesQuery } from "../../../app/services/schedule/schedule";
import { useGetLocationsQuery } from "../../../app/services/location";
import Map from "../locations/Map";

type Props = {
  data: TUser;
};

export const EditEmployee = (props: Props) => {
  const [editUser, editUserData] = useEditUserMutation();

  const [addTimetableToUser, addTimetableResult] =
    useAddTimetableToUserMutation();

  const { data: schedules } = useGetSchedulesQuery({
    limit: 100000000,
  });

  const { data: locations } = useGetLocationsQuery({
    limit: 100000000,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    location: string;
    timeTable: string;
  }>();

  const { t } = useTranslation();

  const onSubmit: SubmitHandler<EditUserPayload> = (data: EditUserPayload) => {
    editUser({
      location: data.location,
      id: props.data._id,
    });

    data.timeTable &&
      addTimetableToUser({ id: props.data._id, timeTable: data.timeTable });
  };

  useEffect(() => {
    if (editUserData.isSuccess)
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
    else if (editUserData.isError)
      toast(t("common.errorOccured") as string, {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
  }, [editUserData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <div className="basic-details flex flex-col space-y-10 ">
        <h1 className="my-5 ">
          <span className="text-2xl text-textMain font-semibold ">
            {t("common:settings.basicDetails")}
          </span>
        </h1>
        <div className="flex flex-col items-start w-full">
          <label className="text-xl w-1/12">{t("columns:timeTable")}</label>
          <div className="flex flex-col gap-2 w-full max-w-[60rem]">
            <div className="flex items-center gap-5 mt-5">
              <span className="text-lg font-bold">
                {props.data.timeTable?.name}
              </span>
              <span className="borde px-4 p-1 capitalize rounded-md bg-primary/10 text-primary">
                {props.data.timeTable?.type}
              </span>
            </div>
            <p className="text-zinc-800">
              A fixed schedule fore mployees who value consistency and routine.
            </p>

            <div className="flex flex-col gap-4 w-full">
              {props.data.timeTable?.workDays.map((el) => {
                return (
                  <div className="flex gap-2 justify-between border border-slate-300 p-4 rounded-md w-full">
                    <span className="font-medium text-zinc-800">{el.day}</span>
                    <div className="flex gap-2 text-zinc-600">
                      <span>{el.from}</span> -<span>{el.to}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-10">
          <div className="flex flex-col w-full justify-start">
            <label className="text-xl w-1/12">{t("columns:location")}</label>
            <div className="flex gap-5 mt-5">
              <div className="flex flex-col gap-2 w-[50%]">
                <span className="text-lg font-bold">
                  {props.data.location?.title}
                </span>
                <span className="text-zinc-800 break-all">
                  {props.data.location?.description}
                </span>
              </div>
            </div>
          </div>
          <Map
            getCoordinates={(data: {
              longitude: number;
              latitude: number;
              radius: number;
            }) => null}
            isDummy={false}
            disableDrag
            className="h-[30rem] w-10/12"
            radius={props.data.location?.radius}
            onSave={() => {}}
            lng={props.data.location?.coordinates[0]}
            lat={props.data.location?.coordinates[1]}
            hideSave
          />
        </div>
        <h1>
          <span className="text-2xl text-textMain font-semibold">
            {t("common:settings.editEmployee")}
          </span>
        </h1>
        <div className="flex items-center w-full justify-between max-w-[60rem] gap-5 pb-10">
          <div className="flex flex-row flex-1">
            <div className="flex flex-row items-center space-x-5 w-full">
              <label className="text-base ">{t("columns:timeTable")}</label>
              <SelectComponent
                className="w-full shadow-none"
                showBorder
                defaultValue={props.data?.timeTable?._id}
                handleChange={(selected: { value: string; label: string }) => {
                  setValue("timeTable", selected.value);
                  //   setSearchType("roles");
                  //   setSearchVal(selected.value === "all" ? "" : selected.value);
                }}
                options={schedules?.result.map((el) => {
                  return {
                    label: el.name,
                    value: el._id,
                  };
                })}
              />
            </div>
          </div>
          <div className="flex flex-row flex-1">
            <div className="flex flex-row items-center space-x-5 w-full">
              <label className="text-base">{t("columns:location")}</label>
              <SelectComponent
                className="w-full shadow-none"
                showBorder
                defaultValue={props.data.location?._id}
                handleChange={(selected: { value: string; label: string }) => {
                  setValue("location", selected.value);

                  //   setSearchType("roles");
                  //   setSearchVal(selected.value === "all" ? "" : selected.value);
                }}
                options={locations?.result.map((el) => {
                  return {
                    label: el.title,
                    value: el._id ?? "",
                  };
                })}
              />
            </div>
          </div>

          <div className="flex flex-row justify-center">
            <button
              className="bg-primary text-white font-normal py-2 px-10 rounded w-full"
              type="submit"
            >
              {editUserData.isLoading ? <Spinner /> : t("buttons:save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
