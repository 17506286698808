import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { ReactComponent as Caret } from "../../../assets/ui/chevron.svg";
import { SelectComponent } from "../../../components/UI/Select";
import { BasicTable } from "../../../components/UI/Table";
import { getTimeSheetColumns } from "../../../data/columns";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { useGetMyEmployeesDailyAttendanceQuery } from "../../../app/services/employees";
import i18n from "../../../app/i18n";

export const Timesheets = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().getMonth().toString()
  );

  const [searchVal, setSearchVal] = useState("");
  const handlePageChange = (e: any) => {
    if (e.selected < 0) return;
    else if (e.selected < currentPage) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleFilterChange = (filter: any) => {
    console.log("filter", filter);
  };

  const { data: employees } = useGetMyEmployeesDailyAttendanceQuery(
    {
      page: currentPage,
      search: searchVal.length > 0 ? "user" : "month",
      searchVal: searchVal.length > 0 ? searchVal : selectedMonth,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { t } = useTranslation();
  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();

  const timesheeCells = getTimeSheetColumns(t);

  const months = [
    { label: t("january"), value: "0" },
    { label: t("february"), value: "1" },
    { label: t("march"), value: "2" },
    { label: t("april"), value: "3" },
    { label: t("may"), value: "4" },
    { label: t("june"), value: "5" },
    { label: t("july"), value: "6" },
    { label: t("august"), value: "7" },
    { label: t("september"), value: "8" },
    { label: t("october"), value: "9" },
    { label: t("november"), value: "10" },
    { label: t("december"), value: "11" },
  ];
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex gap-9 justify-between items-center">
          <SelectComponent
            handleChange={(selected: { label: string; value: string }) => {
              setSelectedMonth(selected.value);
              setSearchVal("");
            }}
            defaultValue={selectedMonth}
            className="max-w-fit"
            options={months}
          />

          {/* <CustomCalendar
            onConfirm={(date) => console.log(date)}
            buttonClassName="w-full"
            initialState={[new Date(), new Date()]}
          /> */}
        </div>
        <div className="relative border border-secondary border-opacity-20 rounded-xl p-2">
          <input
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            className="w-full lg:min-w-[700px] outline-none border-none"
            placeholder={t("search_for_employees")}
          />
          <Search
            className={`absolute ${
              dir === "rtl" ? "left-4" : "right-4"
            } bottom-5`}
          />
        </div>
      </div>
      <BasicTable
        columns={timesheeCells}
        data={employees?.result ?? []}
        onDelete={(values) => {
          console.log(values);
        }}
        onEdit={(values) => {
          console.log(values);
        }}
        onView={(values) => {
          console.log(values);
        }}
        onRowClick={(values) => {
          console.log(values);
        }}
        onFilterChange={handleFilterChange}
        isLoading={false}
        isFetching={false}
      />
      <ReactPaginate
        previousLabel={
          <Caret
            className={`${dir === "rtl" ? "rotate-180" : ""}`}
            direction="left"
            type="caret"
          />
        }
        nextLabel={
          <Caret
            className={`${dir === "rtl" ? "" : "rotate-180"}`}
            direction="right"
            type="caret"
          />
        }
        pageCount={employees ? employees.count / employees.limit : 0}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={
          "pagination self-center mt-8 max-w-[60vw] lg:max-w-full"
        }
        activeClassName={"active"}
      />
    </>
  );
};
