import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEditLocationMutation } from "../../../app/services/location";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";

type Tprops = {
  name: string;
  description: string;
  selected: boolean;
  radius: number;
  isSearching: boolean;
  getRadius: (radius: number) => void;
  setSelected: () => void;
  onDelete: () => void;
  getTitleAndDescription: (data: {
    title: string;
    description: string;
  }) => void;
};

export const LocationCard = (props: Tprops) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [title, setTitle] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [radius, setRadius] = useState(props.radius);

  useEffect(() => {
    props.getTitleAndDescription({ title, description });
  }, [description, title]);

  useEffect(() => {
    setTitle(props.name);
    setDescription(props.description);
    setRadius(props.radius);
  }, [props.selected, props.radius]);

  useEffect(() => {
    props.getRadius(radius);
  }, [radius]);

  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    props.selected && !props.isSearching && ref.current && ref.current.focus();
  }, [props.selected]);

  return (
    <div
      onClick={props.setSelected}
      className={`  flex justify-between  items-start pr-2 gap-2 p-5 border-b border-b-tertiary
       transition-all ${props.selected && "border-l-4 border-primary"}`}
    >
      <div
        className="flex flex-col gap-2 cursor-pointer"
        onClick={() => {
          setIsDisabled(!isDisabled);
          props.setSelected();
        }}
      >
        <input
          className="font-normal border-0"
          value={title}
          ref={ref}
          onBlur={(e) => console.log(e)}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          onChange={(e) => setDescription(e.target.value)}
          // disabled={isDisabled}
          className="text-secondary text-sm"
          value={description}
        />
        <div>
          <input
            value={radius}
            type="number"
            className="font-normal w-3/12 !select-none !underline !border !border-b !outline-none !appearance-none !shadow-none"
            onBlur={(e) => console.log(e)}
            onChange={(e) => setRadius(+e.target.value)}
          />
          <span className="text-secondary">m</span>
        </div>
      </div>
      <Delete className="cursor-pointer" onClick={() => props.onDelete()} />
      {/* <Bin
        onClick={() => setIsDeleteOpen(true)}
        className="w-5 h-5 cursor-pointer hover:fill-red-600 transition-all"
      /> */}
    </div>
  );
};
