import { TLeaveType } from "./leaveType.type";
import { TOrganization } from "./organization.type";
import { TUser } from "./user.type";

export type TUserRequest = {
  _id: string;
  requestType: TLeaveType;
  description: string;
  user: TUser;
  organization: TOrganization;
  startDate: string;
  endDate: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export enum RequestStatus {
  Approved = "approved",
  Declined = "declined",
  Pending = "pending",
}
