import { useState } from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { TFunction } from "react-i18next";
import { ScheduleFormPayload } from "./FormSection";

type FixedSubFormProps = {
  register: UseFormRegister<ScheduleFormPayload>;
  unregister: UseFormUnregister<ScheduleFormPayload>;
  setValue: UseFormSetValue<ScheduleFormPayload>;
  errors: FieldErrors<ScheduleFormPayload>;
  t: TFunction<"translation", undefined>;
};

const WeeklyForm = ({
  register,
  unregister,
  setValue,
  errors,
  t,
}: FixedSubFormProps) => {
  return (
    <>
      <div className="flex items-center gap-5 w-full my-10">
        <label className="font-normal " htmlFor="name">
          {t("schedule.weeklyMin")}
        </label>
        <div className="flex flex-col">
          <div className="relative border border-secondary border-opacity-20 rounded-xl p-[0.3rem]  ml-0">
            <input
              {...register(`weeklyMinHours`, {
                required: "This field is required",
                valueAsNumber: true,
              })}
              type="number"
              className="w-full lg:min-w-[20rem] outline-none border-none"
              placeholder={"20"}
            />
          </div>
          {errors && errors.weeklyMinHours && (
            <span className="text-red-500 text-sm">
              {/* @ts-ignore */}
              {errors.weeklyMinHours.message}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default WeeklyForm;
