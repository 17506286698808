import React, { useMemo, useState } from "react";
import { Column, HeaderGroup, useSortBy, useTable } from "react-table";
import { ReactComponent as Loader } from "../../assets/icons/oval-loading.svg";
import { ReactComponent as DownIcon } from "../../assets/ui/caret-down-icon.svg";

import { useTranslation } from "react-i18next";

export type ViewData = Array<{
  id: string;
  title: string;
  value: string;
}>;

export type IdData = {
  _id: string;
};

export type Filter = { name: string; direction: OrderDirection } | null;
export type OrderDirection = "asc" | "desc";
type Props = {
  data: Array<any>;
  columns: Array<Column>;
  isLoading?: boolean;
  isFetching?: boolean;
  onFilterChange?: (filter: Filter) => void;
  onRowClick?: (data: Record<string, any>) => void;
  onEdit: (id: Record<string, any>) => void;
  onClick?: (id: Record<string, any>) => void;
  onUnsell?: (id: Record<string, any>) => void;
  onDelete?: (id: Record<string, any>) => void;
  onView: (data: ViewData | IdData) => void;
  onSell?: (id: Record<string, any>) => void;
  headerClassName?: string;
  hideHeaders?: boolean;
  hideActions?: boolean;
  customHeaderStyles?: string;
  rowStyle?: string;
};

export const BasicTable = ({
  columns,
  data,
  onEdit,
  onDelete,
  hideHeaders,
  hideActions,
  onUnsell,
  onView,
  onSell,
  customHeaderStyles,
  onRowClick,
  headerClassName,
  isLoading,
  isFetching,
  onFilterChange,
  onClick,
  rowStyle,
  ...rest
}: Props): JSX.Element => {
  const tableColumns = useMemo(() => columns, [columns]);
  const tableData = useMemo(() => data, [data]);
  const { t } = useTranslation();

  const [isSortAsc, setIsSortAsc] = useState(false);
  const [sortId, setSortId] = useState("createdAt");
  // const tableHooks = (hooks: Hooks) => {
  //   // @ts-ignore
  //   hooks.visibleColumns.push((columns: Array<Column>) => [
  //     ...columns,
  //     {
  //       id: "actions",
  //       Header: t("columns:common.actions")
  //         ? t("columns:common.actions").toString()
  //         : "Actions",
  //       Cell: function ImageCell({ value, row }: Cell) {
  //         return (
  //           <div className="flex">
  //             {/* <button
  //               className="p-4 bg-white shadow-sm border border-gray-100 rounded-lg flex justify-center items-center"
  //               onClick={() => onEdit(row.values)}
  //             >
  //               <EditIcon />
  //             </button> */}

  //             {/* <button
  //               onClick={() => {
  //                 const data = row.cells
  //                   .map((cell: any) => {
  //                     return {
  //                       id: cell.column.id,
  //                       title: cell.column.Header,
  //                       value: cell.value,
  //                     };
  //                   })
  //                   .filter((cell: any) => cell.id !== "actions");

  //                 onView({ _id: (row.original as any)._id, ...data });
  //               }}
  //               className="ml-4 p-4 bg-white shadow-sm border border-gray-100 rounded-lg flex justify-center items-center"
  //             >
  //               <ViewIcon />
  //             </button> */}
  //             {/* {onDelete !== undefined && (
  //               <button
  //                 //fix the error of the button

  //                 onClick={() => onDelete(row.values)}
  //                 className="ml-4 p-4 bg-white shadow-sm border border-gray-100 rounded-lg flex justify-center items-center"
  //               >
  //                 <DeleteIcon fill="currentColor" className="text-kazoRed" />
  //               </button>
  //             )} */}
  //           </div>
  //         );
  //       },
  //     },
  //   ]);
  // };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data: tableData,
        manualSortBy: true,
        initialState: {
          hiddenColumns: ["_id", "user._id"],
        },
      },
      // tableHooks,
      useSortBy
    );

  const handleOnColumnClick = (column: HeaderGroup<any>) => {
    // If it has a dot in it, it's a nested column, we just need the second part
    const columnName = column.id.includes(".")
      ? column.id.split(".")[1]
      : column.id;
    setSortId(column.id);
    let filter: Filter = null;
    if (column.id !== "actions") {
      setIsSortAsc(!isSortAsc);
      filter = {
        name: columnName,
        direction: isSortAsc ? "asc" : "desc",
      };
    }

    onFilterChange !== undefined && onFilterChange(filter);
  };
  return (
    <div className="whitespace-nowrap rounded-lg mt-8  w-full">
      <table {...getTableProps()} className={`w-full`} {...rest}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() => handleOnColumnClick(column)}
                  key={column.id}
                  className={`px-10 py-8 bg-white shadow-rowBorder border-b-2 border-secondary border-opacity-25 text-description font-medium text-center ${
                    hideHeaders && "hidden"
                  } ${customHeaderStyles}`}
                >
                  <span className="flex items-center text-black text-center">
                    {column.render("Header")}

                    {sortId === column.id &&
                      (isSortAsc ? (
                        <DownIcon
                          fill="currentColor"
                          className="text-description ml-4"
                          transform="rotate(180)"
                        />
                      ) : (
                        <DownIcon
                          fill="currentColor"
                          className="text-description ml-4"
                        />
                      ))}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {isLoading ? (
          <tbody className="relative h-20">
            <div className="flex justify-center items-center absolute left-1/2 top-1/2">
              <Loader className="w-8 h-8 stroke-current text-primary" />
            </div>
          </tbody>
        ) : (
          <tbody
            {...getTableBodyProps()}
            className={`${rows?.length === 0 ? "relative h-20 " : ""}`}
          >
            {isFetching && (
              <div className="flex justify-center items-center absolute left-1/2 top-1/2 z-50">
                <Loader className="w-8 h-8 stroke-current text-primary" />
              </div>
            )}
            {rows.length === 0 && (
              <div
                className="flex justify-center items-center absolute top-1/2 text-xl text-center px-5"
                // style={isRtl ? { right: "40%" } : { left: "40%" }}
              >
                {t("common.noData")}
              </div>
            )}
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={row.id + row.index}
                  className={`${
                    isFetching ? "opacity-30 pointer-events-none relative " : ""
                  }`}
                  // onClick={onRowClick !== undefined ? () => onRowClick(row.values) : undefined}
                >
                  {row.cells.map((cell, i) => {
                    const isLastCell = i === row.cells.length - 1;
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={cell.row.id + " " + cell.column.id}
                        className={`p-5 bg-white border-b-2 border-secondary border-opacity-10 text-main font-normal text-left ${rowStyle}`}
                        onClick={
                          !isLastCell
                            ? () =>
                                onRowClick !== undefined
                                  ? onRowClick(row.values)
                                  : undefined
                            : undefined
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};
