import { toast } from "react-toastify";
import {
  useBreakInMutation,
  useBreakOutMutation,
  useClockInMutation,
  useClockOutMutation,
} from "../app/services/attendance";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeClock, setRunning } from "../app/slices/attendanceSlices";
import { RootState } from "../app/store";
import { getValueFromLang } from "../utils/utils";
import { useTranslation } from "react-i18next";

export type ClockType =
  | "none"
  | "clockIn"
  | "clockOut"
  | "breakIn"
  | "breakOut";

export const useClockResponses = ({ type }: { type: ClockType }) => {
  const dispatch = useDispatch();
  const clock = useSelector((state: RootState) => state.attendance.clock);
  const running = useSelector((state: RootState) => state.attendance.running);

  const [coordinates, setCoordinates] = useState({
    longitude: 0,
    latitude: 0,
  });

  const { t } = useTranslation();
  const [clockIn, clockInResult] = useClockInMutation();
  const [breakIn, breakInResult] = useBreakInMutation();
  const [breakOut, breakOutResult] = useBreakOutMutation();
  const [clockOut, clockOutResult] = useClockOutMutation();

  // const [running, setRunning] = useState(false);

  const runQuery = useCallback(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoordinates({
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
      });
    });

    if (type === "clockIn") {
      clockIn(coordinates);
    } else if (type === "breakIn") {
      breakIn();
    } else if (type === "breakOut") {
      breakOut(coordinates);
    } else if (type === "clockOut") {
      if (!clock.breakOut) {
        breakOut(coordinates);
      }
      clockOut(coordinates);
    }
  }, [type]);

  useEffect(() => {
    if (type === "clockIn") {
      checkResult(clockInResult, true);
    } else if (type === "breakIn") {
      checkResult(breakInResult, false);
    } else if (type === "breakOut") {
      checkResult(breakOutResult, true);
    } else if (type === "clockOut") {
      checkResult(clockOutResult, false);
    }
  }, [clockInResult, clockOutResult, breakInResult, breakOutResult]);

  const checkResult = (result: any, isRunning: boolean) => {
    if (result.isError) {
      toast.error(getValueFromLang(result.error?.data?.errorMessage), {
        position: "top-center",
        autoClose: 1500,
      });
    }
    if (result.isSuccess) {
      dispatch(changeClock({ ...clock, [type]: new Date().toISOString() }));
      // setClock();
      dispatch(setRunning(isRunning));
      toast.success(`${t("common.success")}`, {
        position: "top-center",
        autoClose: 1500,
      });
    }
  };

  //   const runQuery = (type) => {
  //     if (type === "clockin") clockIn();
  //   };

  return {
    runQuery,
    running,
    isCILoading: clockInResult.isLoading,
    isCOLoading: clockOutResult.isLoading,
    isBILoading: breakInResult.isLoading,
    isBOLoading: breakOutResult.isLoading,
  };
};
