import { TFunction } from "react-i18next";
import { Cell, Column } from "react-table";
import { TEmployee } from "../app/types/employee.type";
import { ActionsTypeCell } from "../components/UI/table/ActionsCell";
import { HoursCell } from "../components/UI/table/HoursCell";
import { LeaveActionCell } from "../components/UI/table/LeaveActionCell";
import { LeaveTypeCell } from "../components/UI/table/LeaveTypeCell";
import { NameCell } from "../components/UI/table/NameCell";
import { StatusTypeCell } from "../components/UI/table/StatusTypeCell";
import { TimesheetCell } from "../components/UI/table/TimesheetCell";
import { TimeSheetHeader } from "../components/UI/table/TimeSheetHeader";
import { RolesCell } from "../components/UI/table/RolesCell";
import { DateCell } from "../components/UI/table/DateCell";
import { PlainDateCell } from "../components/UI/table/PlainDateCell";
import { DateWithTimeCell } from "../components/UI/table/DateWithTimeCell";
import { BreakCell } from "../components/UI/table/BreakCell";

export function getEmployeeColumns(t: TFunction<"translation">): Array<Column> {
  return [
    {
      Header: "ID",
      accessor: "user._id",
    },

    {
      Header: t("columns:employee.name")
        ? t("columns:employee.name").toString()
        : "Name",
      accessor: "user.fullName",
      Cell: NameCell,
    },

    {
      Header: t("columns:employee.role")
        ? t("columns:employee.role").toString()
        : "Roles",
      accessor: "user.roles",
      Cell: RolesCell,
    },
    {
      Header: t("columns:employee.total")
        ? t("columns:employee.total").toString()
        : "Phone Number",
      accessor: "totalHours",
      Cell: HoursCell,
    },
  ];
}

export function getTimeSheetColumns(
  t: TFunction<"translation">
): Array<Column> {
  return [
    {
      Header: "ID",
      accessor: "_id",
    },

    {
      Header: t("columns:employee.employeeName")
        ? t("columns:employee.employeeName").toString()
        : "Employee Name",
      accessor: "user.fullName",
      Cell: NameCell,
    },

    {
      Header: TimeSheetHeader,
      accessor: "user",
      Cell: TimesheetCell,
    },
    {
      Header: t("columns:employee.totalHours")
        ? t("columns:employee.totalHours").toString()
        : "Total Hours",
      accessor: "totalHours",
      Cell: HoursCell,
    },
  ];
}

export function getRequestsColumns(t: TFunction<"translation">): Array<Column> {
  return [
    {
      Header: "ID",
      accessor: "_id",
    },
    {
      Header: t("columns:request.employeeName")
        ? t("columns:request.employeeName").toString()
        : "Employee Name",
      accessor: "user.fullName",
      Cell: NameCell,
    },

    {
      Header: t("columns:request.leaveType")
        ? t("columns:request.leaveType").toString()
        : "Leave Type",
      accessor: "requestType.name",
      Cell: LeaveTypeCell,
    },
    {
      Header: t("columns:request.date")
        ? t("columns:request.date").toString()
        : "Date",
      accessor: "startDate",
      Cell: DateCell,
    },
    {
      Header: t("columns:request.status")
        ? t("columns:request.status").toString()
        : "Status",
      accessor: "status",
      Cell: StatusTypeCell,
    },
    {
      Header: t("columns:request.actions")
        ? t("columns:request.actions").toString()
        : "Actions",
      accessor: "actions",
      Cell: ActionsTypeCell,
    },
  ];
}

export function getLeavesColumns(t: TFunction<"translation">): Array<Column> {
  return [
    {
      Header: "ID",
      accessor: "_id",
    },
    {
      Header: t("columns:request.leaveType")
        ? t("columns:request.leaveType").toString()
        : "Leave Type",
      accessor: "requestType.name",
      Cell: LeaveTypeCell,
    },
    {
      Header: t("columns:request.date")
        ? t("columns:request.date").toString()
        : "Date",
      accessor: "startDate",
      Cell: DateCell,
    },
    {
      Header: t("columns:request.status")
        ? t("columns:request.status").toString()
        : "Status",
      accessor: "status",
      Cell: StatusTypeCell,
    },
    {
      Header: t("columns:request.actions")
        ? t("columns:request.actions").toString()
        : "Actions",
      accessor: "actions",
      Cell: ActionsTypeCell,
    },
  ];
}

export function getAttendanceColumns(
  t: TFunction<"translation">
): Array<Column> {
  return [
    {
      Header: "ID",
      accessor: "_id",
    },
    {
      Header: t("columns:attendance.date")
        ? t("columns:attendance.date").toString()
        : "Date",
      accessor: "date",
      Cell: PlainDateCell,
    },
    {
      Header: t("columns:attendance.clockIn")
        ? t("columns:attendance.clockIn").toString()
        : "Clock In",
      accessor: "clockIn",
      Cell: DateWithTimeCell,
    },

    {
      Header: t("columns:attendance.break")
        ? t("columns:attendance.break").toString()
        : "break",
      accessor: "break",
      Cell: BreakCell,
    },

    {
      Header: t("columns:attendance.clockOut")
        ? t("columns:attendance.clockOut").toString()
        : "clockOut",
      accessor: "clockOut",
      Cell: DateWithTimeCell,
    },
    {
      Header: t("columns:attendance.workingHours")
        ? t("columns:attendance.workingHours").toString()
        : "workingHours",
      accessor: "workedHours",
      Cell: HoursCell,
    },
  ];
}
