import React, { useEffect, useState } from "react";
import { BasicTable } from "../../../components/UI/Table";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { getRequestsColumns } from "../../../data/columns";
import { useTranslation } from "react-i18next";
import {
  useGetLeaveRequestsQuery,
  useLazyGetARequestQuery,
} from "../../../app/services/user-request";
import ReactPaginate from "react-paginate";
import { ReactComponent as Caret } from "../../../assets/ui/chevron.svg";
import { RequestStatus } from "../../../app/types/user-request.type";
import { SelectComponent } from "../../../components/UI/Select";
import { getStatusTypeColor } from "../../../utils/utils";
import Modal from "../../../components/UI/Modal";
import { ViewContext } from "../../../utils/ViewContext";
import moment from "moment";

import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";
import { ReactComponent as CalanderIcon } from "../../../assets/icons/calander.svg";
import { toast } from "react-toastify";
import i18n from "../../../app/i18n";

export const Requests: () => JSX.Element = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<string | undefined>();
  const [searchVal, setSearchVal] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().getMonth().toString()
  );

  const { t } = useTranslation();
  const handleFilterChange = (filter: any) => {
    console.log("filter", filter);
  };

  const { data } = useGetLeaveRequestsQuery(
    {
      search: searchVal.length > 0 ? "user" : "createdAt",
      searchVal: searchVal.length > 0 ? searchVal : selectedMonth,
      page: currentPage,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [getRequestById, { data: selectedRequestData }] =
    useLazyGetARequestQuery();

  useEffect(() => {
    selectedRequest && getRequestById(selectedRequest);
  }, [selectedRequest]);

  const requestColumns = getRequestsColumns(t);
  const handlePageChange = (e: any) => {
    if (e.selected < 0) return;
    else if (e.selected < currentPage) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const months = [
    { label: t("january"), value: "0" },
    { label: t("february"), value: "1" },
    { label: t("march"), value: "2" },
    { label: t("april"), value: "3" },
    { label: t("may"), value: "4" },
    { label: t("june"), value: "5" },
    { label: t("july"), value: "6" },
    { label: t("august"), value: "7" },
    { label: t("september"), value: "8" },
    { label: t("october"), value: "9" },
    { label: t("november"), value: "10" },
    { label: t("december"), value: "11" },
  ];

  const dir = ["ku"].includes(i18n.language) ? "rtl" : i18n.dir();
  return (
    <div>
      <div className="top-section flex flex-row place-items-center w-full  align-middle justify-between">
        <SelectComponent
          handleChange={(selected: { label: string; value: string }) => {
            setSelectedMonth(selected.value);
            setSearchVal("");
          }}
          defaultValue={selectedMonth}
          className="max-w-[150px]"
          options={months}
        />

        <div className="relative border-1   border-neutral-200   w-4/12">
          <span
            className={`absolute inset-y-0 ${
              dir === "rtl" ? "left-4" : "right-4"
            } flex items-center pl-2`}
          >
            <button
              type="submit"
              className="p-1 focus:outline-none focus:shadow-outline"
            >
              <Search />
            </button>
          </span>
          <input
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            type="search"
            name="q"
            className="py-2 text-sm  bg-gray-900 rounded-lg px-6 h-12  focus:outline-none focus:bg-white focus:text-gray-900 w-full border-1  border-neutral-200  "
            placeholder="Search For Employees..."
          />
        </div>
      </div>

      <div className="body-section">
        <ViewContext.Provider
          value={[
            { openModal: () => setIsModalOpen(true) },
            { getId: (id: string) => setSelectedRequest(id) },
          ]}
        >
          <BasicTable
            columns={requestColumns}
            data={data?.result ?? []}
            onDelete={(values) => {
              console.log(values);
            }}
            onEdit={(values) => {
              console.log(values);
            }}
            onView={(values) => {
              console.log(values);
            }}
            onRowClick={(values) => {
              console.log(values);
            }}
            onFilterChange={handleFilterChange}
            isLoading={false}
            isFetching={false}
          />
        </ViewContext.Provider>
        <ReactPaginate
          previousLabel={
            <Caret
              className={`${dir === "rtl" ? "rotate-180" : ""}`}
              direction="left"
              type="caret"
            />
          }
          nextLabel={
            <Caret
              className={`${dir === "rtl" ? "" : "rotate-180"}`}
              direction="right"
              type="caret"
            />
          }
          pageCount={data ? data?.count / data?.limit : 0}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={
            "pagination self-center mt-8 max-w-[60vw] lg:max-w-full"
          }
          activeClassName={"active"}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancelClick={() => {}}
        hasFooter={true}
        title={t("request.modal.viewRequest")}
        onConfirm={() => {}}
        isLoading={false}
        isConfirmDisabled={
          selectedRequestData?.status === RequestStatus.Approved
        }
        isCancelDisabled={
          selectedRequestData?.status === RequestStatus.Declined
        }
        confirmText={t("common.accept")}
        cancelText={t("common.decline")}
        className="max-w-lg"
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <img
              src={selectedRequestData?.user.avatar}
              className="w-16 h-16 rounded-[50%]"
              alt="pfp"
            />
            <div className="flex flex-col">
              <span className="font-medium">
                {selectedRequestData?.user?.fullName}
              </span>
              <div className="flex items-center gap-2">
                <ClockIcon className="fill-secondary w-5 h-5" />

                <span className="text-sm text-secondary">
                  {moment(selectedRequestData?.startDate!).format(
                    "MMM Do YYYY"
                  )}{" "}
                  -{" "}
                  {moment(selectedRequestData?.endDate!).format("MMM Do YYYY")}
                </span>
              </div>
            </div>
          </div>
          <span
            className={
              "border-2 px-5 py-2 rounded-lg text-sm " +
              getStatusTypeColor(selectedRequestData?.status ?? "")
            }
          >
            {selectedRequestData?.status}
          </span>
        </div>

        <div className="flex flex-col gap-10">
          <span className="border-2 px-5 py-2 rounded-lg text-sm bg-blue-100 w-fit text-primary font-medium">
            {selectedRequestData &&
              new Date(selectedRequestData?.startDate).getDay() -
                new Date(selectedRequestData?.endDate).getDay()}{" "}
            {t("common.days")}
          </span>
          <div className="flex gap-2">
            <div className="flex gap-1">
              <CalanderIcon className="fill-secondary w-5 h-5" />
              <span className="text-secondary text-sm">
                {t("request.leaveFrom")}:{" "}
              </span>
            </div>
            <span className="font-medium text-sm">
              {moment(selectedRequestData?.startDate!).format("MMM Do")} -{" "}
              {moment(selectedRequestData?.endDate!).format("MMM Do")}
            </span>
          </div>
          {/* <span>manager</span> */}
        </div>
        <div className="flex flex-col max-w-[20rem]">
          <span className="font-medium">description</span>
          <p className="text-secondary text-sm">
            {selectedRequestData?.description}
          </p>
        </div>
      </Modal>
    </div>
  );
};
