import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ClockState {
  clockIn: string | undefined;
  clockOut: string | undefined;
  breakIn: string | undefined;
  breakOut: string | undefined;
}

export interface AttendanceState {
  clock: ClockState;
  startTime: number;
  running: boolean;
}

const initialState: AttendanceState = {
  clock: {
    clockIn: undefined,
    clockOut: undefined,
    breakIn: undefined,
    breakOut: undefined,
  },
  startTime: 0,
  running: false,
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    changeClock: (state, action: PayloadAction<ClockState>) => {
      state.clock = action.payload;
    },
    setStartTime: (state, action: PayloadAction<number>) => {
      state.startTime = action.payload;
    },
    setRunning: (state, action: PayloadAction<boolean>) => {
      state.running = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeClock, setStartTime, setRunning } =
  attendanceSlice.actions;

export default attendanceSlice.reducer;
