import dayjs from "dayjs";
import { motion } from "framer-motion";
import { createRef, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useClickAway } from "react-use";
import { ReactComponent as DraggableIcon } from "../../assets/icons/draggable-icon.svg";
import { ReactComponent as ResetIcon } from "../../assets/icons/reset-icon.svg";

import Caret from "../UI/Caret";
import "./customCalendar.css";
import i18n from "../../app/i18n";
import { useTranslation } from "react-i18next";
import Button from "./Button";

type Props = {
  onConfirm: (date: Date[]) => void;
  onlyShowDate?: boolean;
  buttonClassName?: string;
  singleDate?: boolean;
  initialState: Date[];
};

export function lastYear(): Date {
  // Get a date object for the current time
  const d = new Date();

  // Set it to one year ago
  d.setFullYear(d.getFullYear() - 2);

  // Zero the time component
  // d.setHours(0, 0, 0, 0);

  return d;
}

export default function CustomCalendar({
  onConfirm,
  onlyShowDate = false,
  buttonClassName,
  initialState,
}: Props): JSX.Element {
  // const initialState = [new Date(), new Date()];

  const fromBeginning = [new Date(0), new Date()];
  const [date, setDate] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);

  const [counter, setCounter] = useState(0);

  const calendarRef = useRef(null);

  const containerRef = useRef(null);
  useClickAway(calendarRef, () => {
    setIsOpen(!isOpen);
    setDate(initialState);
  });

  const handleOnChange = (date: Date[]) => {
    setDate([date[0], date[1]]);
    setCounter(counter + 1);
  };

  const handleSetDate = () => {
    onConfirm(date);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setDate(initialState);
  };

  const constraintRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
  }, []);

  const callbackRef = (node: HTMLDivElement) => {
    if (node && i18n.language === "ku") {
      node.focus();
      // compose(node);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="relative" ref={constraintRef}>
      <button
        type="button"
        className={`mr-8 bg-white rounded-lg p-4 font-medium shadow-dateButton focus:shadow-sm ${buttonClassName}`}
        style={{ zIndex: -1 }}
        onClick={() => setIsOpen(true)}
      >
        {!onlyShowDate && t("calendar.showingData")}
        <span className="text-textColor font-medium">
          {!onlyShowDate && " " + t("calendar.from") + " "}
          <span className="text-description">
            {" " + dayjs(date[0]).format("DD MMM YYYY")}{" "}
          </span>
          {t("calendar.to") + " "}
          <span className="text-description">
            {dayjs(date[1]).format("DD MMM YYYY")}
          </span>
        </span>
      </button>

      {isOpen && (
        <motion.div
          className="calendar-container absolute top-16"
          ref={calendarRef}
          drag
          whileDrag={{ scale: 0.9 }}
          // dragConstraints={constraintRef}
          style={{ width: i18n.language === "ku" ? "432px" : "390px" }}
          dragMomentum={false}
        >
          <button type="button">
            <DraggableIcon
              className="absolute left-4 top-4 fill-current text-description"
              width={24}
              height={24}
            />
          </button>
          <button
            type="button"
            onClick={() => setDate(fromBeginning)}
            className="absolute right-24 top-4 flex items-centertext-textColor font-medium"
          >
            <span className="text-primary mr-2 underline hover:text-primaryHover">
              All time
            </span>
          </button>
          <button
            type="button"
            onClick={() => setDate(initialState)}
            className="absolute right-4 top-4 flex items-centertext-textColor font-medium"
          >
            <span className="text-primary mr-2 underline hover:text-primaryHover">
              Reset
            </span>
            {/* <ResetIcon className="fill-current text-description" width={20} height={20} /> */}
          </button>
          <div ref={callbackRef} className="mt-4">
            <Calendar
              selectRange={true}
              onChange={(date: Date[]) => handleOnChange([date[0], date[1]])}
              prevLabel={<Caret direction="left" type="chevron" />}
              nextLabel={<Caret direction="right" type="chevron" />}
              prev2Label={
                <div className="flex justify-center items-center">
                  <Caret direction="left" type="chevron" />
                  <Caret direction="left" type="chevron" />
                </div>
              }
              next2Label={
                <div className="flex justify-center items-center">
                  <Caret direction="right" type="chevron" />
                  <Caret direction="right" type="chevron" />
                </div>
              }
              locale={i18n.language}
              value={date as [Date, Date]}
            />
          </div>

          <div className="flex justify-between mt-8">
            <Button variant="secondary" onClick={handleCancel} type="button">
              {t("common.cancel")}
            </Button>
            <Button variant="primary" onClick={handleSetDate} type="button">
              {t("calendar.setDate")}
            </Button>
          </div>
        </motion.div>
      )}
    </div>
  );
}
