import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TAttendance } from "../../../app/types/attendance.type";
import moment from "moment";
import i18n from "../../../app/i18n";
import { TimesheetContext } from "../../../utils/TimesheetContext";

//make an array of length 31
const array = Array.from(Array(31).keys());
export const TimesheetCell = ({ value, row }: any) => {
  const [dropDownHidden, setDropDownHidden] = useState(true);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [currentAttendance, setCurrentAttendance] = useState<
    TAttendance | undefined
  >();

  const { t } = useTranslation();

  const timesheetValue = useContext(TimesheetContext);

  useEffect(() => {
    timesheetValue?.activeUserId !== value._id && setDropDownHidden(true);
  }, [timesheetValue?.activeUserId]);

  return (
    <div className="flex gap-2 relative">
      {array.map((_, index) => {
        return (
          <span
            onClick={() => {
              setDropDownHidden(false);
              setActiveIndex(index);

              setCurrentAttendance(
                row.original.attendances.find(
                  (el: TAttendance) => moment(el.date).date() === index + 1
                )
              );
              timesheetValue && timesheetValue.setActiveUserId(value._id);
            }}
            className={`${
              row.original.attendances.find(
                (el: TAttendance) => moment(el.date).date() === index + 1
              )
                ? "bg-primary"
                : "bg-tertiary"
            }  rounded-sm w-5 h-5 inline-block mx-[0.8px] cursor-pointer`}
          ></span>
        );
      })}
      <div
        className={`absolute top-7  p-3 min-w-[9rem] min-h-[9rem]  z-50 bg-white ${
          dropDownHidden ? "invisible opacity-0" : "visible opacity-100"
        } transition-all flex flex-col items-center justify-start border border-tertiary rounded-lg
        `}
        style={
          ["ku", "ar"].includes(i18n.language)
            ? { right: `${activeIndex * 20}px` }
            : { left: `${activeIndex * 20}px` }
        }
      >
        {currentAttendance &&
        timesheetValue &&
        timesheetValue.activeUserId === value._id ? (
          <div className="flex flex-col gap-1 text-sm relative">
            <span
              onClick={() => {
                setDropDownHidden(!dropDownHidden);
              }}
              className="ml-auto mb-2 cursor-pointer"
            >
              X
            </span>
            <div className="flex gap-1 w-full justify-center mb-2">
              <span className="font-semibold text-center">
                {moment(moment(currentAttendance.date)).format("yy-MM-DD")}
              </span>
            </div>
            <div className="flex gap-1">
              <span className="text-secondary"> {t("common.clockIn")}: </span>
              <span className="font-semibold">
                {moment(currentAttendance?.clockIn).format("hh:mm A")}
              </span>
            </div>
            <div className="flex gap-1">
              <span className="text-secondary"> {t("common.breakIn")}:</span>
              <span className="font-semibold">
                {moment(currentAttendance?.breakIn).format("hh:mm A")}
              </span>
            </div>
            <div className="flex gap-1">
              <span className="text-secondary"> {t("common.breakOut")}:</span>
              <span className="font-semibold">
                {moment(currentAttendance?.breakOut).format("hh:mm A")}
              </span>
            </div>
            <div className="flex gap-1">
              <span className="text-secondary"> {t("common.clockOut")}:</span>
              <span className="font-semibold">
                {moment(currentAttendance?.clockOut).format("hh:mm A")}
              </span>
            </div>
          </div>
        ) : (
          timesheetValue &&
          timesheetValue.activeUserId === value._id && (
            <>
              <span
                onClick={() => {
                  setDropDownHidden(!dropDownHidden);
                }}
                className="ml-auto mb-2 cursor-pointer"
              >
                X
              </span>
              <div className="text-sm text-start mr-12">
                {t("common.absent")}
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};
