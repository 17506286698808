import { useTranslation } from "react-i18next";

type LeaveTypeCellProps = {
  value: string;
};

export const LeaveTypeCell = ({ value }: LeaveTypeCellProps) => {
  // const getLeaveTypeColor = (leaveType: string) => {
  //   switch (leaveType) {
  //     case "casual":
  //       return "bg-blue-50  text-blue-400";

  //     case "maternity":
  //       return "bg-orange-50 text-orange-400  text-orange-400";

  //     case "sick":
  //       return "bg-purple-50 text-purple-400  text-purple-400";

  //     default:
  //       return "bg-gray-50 text-gray-400  text-gray-400";
  //   }
  // };

  return (
    <div className="">
      <span
        className={
          "border-2 px-5 w py-2 rounded-lg text-sm bg-blue-50 text-blue-400"
          // getLeaveTypeColor(value?.toLowerCase())
        }
      >
        {value && value[0]?.toUpperCase() + value?.slice(1)}
      </span>
    </div>
  );
};
