import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useApproveRequestMutation,
  useDeclineRequestMutation,
} from "../../../app/services/user-request";
import { RequestStatus } from "../../../app/types/user-request.type";
import { ViewContext } from "../../../utils/ViewContext";
import { ApiError, extractErrMsg } from "../../../utils/utils";

export const ActionsTypeCell = ({ value, row }: any) => {
  const [approveRequest, approveResult] = useApproveRequestMutation();
  const [declineRequest, declineResult] = useDeclineRequestMutation();

  const viewValue = useContext(ViewContext);

  const { t } = useTranslation();

  useEffect(() => {
    if (approveResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      approveResult.reset();
    } else if (approveResult.isError) {
      toast(extractErrMsg(approveResult.error as ApiError), {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
      approveResult.reset();
    } else if (declineResult.isSuccess) {
      toast(t("common.success") as string, {
        type: "success",
        autoClose: 2000,
        position: "top-center",
      });
      declineResult.reset();
    } else if (declineResult.isError) {
      toast(extractErrMsg(approveResult.error as ApiError), {
        type: "error",
        autoClose: 2000,
        position: "top-center",
      });
      declineResult.reset();
    }
  }, [approveResult, declineResult]);
  return (
    <div className="flex flex-row space-x-3 place-items-center">
      <button
        disabled={row.original?.status === RequestStatus.Approved}
        onClick={() => approveRequest(row.original?._id)}
        className={`${
          row.original.status === RequestStatus.Approved
            ? "bg-slate-600"
            : "bg-primary"
        }  text-white border-2 px-5 w py-2 rounded-lg text-sm`}
      >
        {t("common.accept")}
      </button>
      <button
        onClick={() => {
          if (viewValue) {
            viewValue[0].openModal();
            viewValue[1].getId(row.original?._id);
          }
        }}
        className="bg-white text-primary border-gray border-2 px-5 w py-2 rounded-lg text-sm "
      >
        {t("common.view")}
      </button>
      <button
        disabled={row.original?.status === RequestStatus.Declined}
        onClick={() => declineRequest(row.original?._id)}
        className={`bg-white ${
          row.original?.status === RequestStatus.Declined
            ? "text-slate-600"
            : "text-error"
        } border-gray border-2 px-5 w py-2 rounded-lg text-sm`}
      >
        {t("common.decline")}
      </button>
    </div>
  );
};
