import queryString from "query-string";
import { PaginatedResponse, Pagination } from "../../../utils/utils";
import { baseApi } from "../baseApi";
import { ScheduleFormPayload } from "../../../routes/dashboard/schedules/FormSection";
import { TTimeTable } from "../../types/timeTable.type";

const scheduleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    addSchedule: builder.mutation<TTimeTable, ScheduleFormPayload>({
      query: (body) => ({
        url: "/timetable/me",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Schedules"],
    }),
    updateTimetable: builder.mutation<
      TTimeTable,
      ScheduleFormPayload & { id?: string }
    >({
      query: (payload) => {
        const id = payload.id;
        delete payload.id;
        return {
          url: `/timetable/${id}`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["Schedules"],
    }),

    deleteSchedule: builder.mutation<void, string>({
      query: (id) => ({
        url: `timetable/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Schedules"],
    }),

    getSchedules: builder.query<
      PaginatedResponse<TTimeTable>,
      Pagination<TTimeTable>
    >({
      query: (payload) => ({
        url: `timetable/me?${queryString.stringify(payload)}`,
      }),
      providesTags: ["Schedules"],
    }),
  }),
});

export const {
  useAddScheduleMutation,
  useGetSchedulesQuery,
  useDeleteScheduleMutation,
  useUpdateTimetableMutation,
} = scheduleApi;
